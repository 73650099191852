import { useState } from "react";
import { FILE } from "../../constants/images";

export default function UIFileInput({
  id,
  label,
  name,
  isRequired,
  placeholder,
  style,
  inputStyle,
  onChange,
  error,
  instruction,
  multiple,
}) {
  const [fileList, setFileList] = useState(null);
  const handleFileChange = (e) => {
    const files = e.target.files;
    if (!files || files?.length < 1) return;

    const existingFilesArray = fileList ? Array.from(fileList) : [];

    const combinedFiles = existingFilesArray.concat(Array.from(files));

    const dataTransfer = new DataTransfer();
    combinedFiles.forEach((file) => dataTransfer.items.add(file));

    setFileList(dataTransfer.files);
    onChange &&
      onChange({
        target: {
          name: name ?? "File Input",
          files: dataTransfer.files,
          required: isRequired ?? false,
          type: "file",
        },
      });
  };
  const handleFileDelete = (item) => {
    const files = (fileList ? Array.from(fileList) : []).filter(
      (_, index) => index !== item
    );

    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));

    setFileList(dataTransfer.files);
    onChange &&
      onChange({
        target: {
          name: name ?? "File Input",
          files: dataTransfer.files,
          required: isRequired ?? false,
          type: "file",
        },
      });
  };

  return (
    <div className="filelistinput">
      {label ? (
        <label htmlFor={id ? id : ""} className="filelistinput-label">
          {label} {isRequired ? "*" : ""}
        </label>
      ) : null}

      <div className={`filelistinput-input ${error ? "error" : ""}`}>
        {!multiple && fileList?.length === 1 ? null : (
          <div
            className="filelistinput-input--add"
            style={inputStyle ? inputStyle : style}
          >
            <i className="fa-regular fa-plus "></i>
            <span>
              {placeholder
                ? placeholder
                : fileList && fileList?.length > 0
                ? fileList?.length + " Items Selected"
                : "Drop or Click"}
            </span>
            <input
              type={"file"}
              onChange={handleFileChange}
              id={id}
              name={name}
              required={isRequired}
              multiple={multiple}
              accept="image/*"
            />
          </div>
        )}
        {fileList &&
          Object.values(fileList).map((item, index) => (
            <div
              className="filelistinput-input--image"
              key={index}
              style={style}
            >
              <i
                className="fa-regular fa-times"
                onClick={() => handleFileDelete(index)}
              ></i>
              <img
                key={index}
                src={
                  item.type.indexOf("image/") > -1
                    ? URL.createObjectURL(item)
                    : FILE
                }
                alt={item.name}
                width={1000}
                height={1000}
                quality={25}
              />
            </div>
          ))}
      </div>

      {error ? <span className="filelistinput-error">{error}</span> : null}

      {instruction ? (
        <p className="filelistinput-instruction">{instruction}</p>
      ) : null}
    </div>
  );
}
