import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPatch, doPost } from "../../utils/axios";

export const getBlog = createAsyncThunk("getBlog", async ({ token }) => {
  try {
    const response = await doGet(`/blogs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
});

export const insertBlog = createAsyncThunk(
  "insertBlog",
  async ({ data, token, callback }) => {
    try {
      const response = await doPost(`/blogs`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const editBlog = createAsyncThunk(
  "editBlog",
  async ({ data, token, id, callback }) => {
    try {
      const response = await doPatch(`/blogs/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "deleteBlog",
  async ({ id, token, callback }) => {
    try {
      await doDelete(`/blogs/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return id;
    } catch (error) {
      throw error;
    }
  }
);
