import { createSlice } from "@reduxjs/toolkit";

import { API_EXPIRE_TIME } from "../../constants/constants";
import {
  deleteTestimonial,
  editTestimonial,
  getTestimonial,
  insertTestimonial,
} from "../thunks/testimonialThunk";
const initialState = {
  data: [],
  loading: false,
  expires: null,
  error: null,
  status: "All",
  disableConfig: false,
};

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //!Fetch
      .addCase(getTestimonial.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getTestimonial.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getTestimonial.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch testimonials data";
        state.expires = null;
      })
      .addCase(insertTestimonial.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertTestimonial.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [action.payload, ...state.data];
        state.error = null;
      })
      .addCase(insertTestimonial.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to insert testimonial data";
      })
      .addCase(deleteTestimonial.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTestimonial.fulfilled, (state, action) => {
        state.loading = false;

        state.data = state.data.filter((i) => i._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteTestimonial.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to delete testimonial data";
      })
      //!Edit
      .addCase(editTestimonial.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editTestimonial.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.map((i) =>
          i._id === action.payload._id ? { ...i, ...action.payload } : i
        );
        state.error = null;
      })

      .addCase(editTestimonial.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to edit testimonial data";
      });
  },
});

// export const { setStatus } = testimonialSlice.actions;
export default testimonialSlice.reducer;
