import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import UIButton from "../../ui/uibutton/UIButton";
import UISelect from "../../ui/uiselect/UISelect";
import UIDialogBox from "../../ui/uidialogbox/UIDialogBox";
import { UIInput } from "../../ui/uiinput/UIInput";
import { NO_IMAGE } from "../../constants/images";
import UIModal from "../../ui/uimodal/UIModal";
import UIFileInput from "../../ui/uifileinput/UIFileInput";
import { parseInputType } from "../../utils/helper";
import { errorToast, successToast } from "../../utils/toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategory,
  getSubCategoryByCategory,
} from "../../redux/thunks/categoryThunk";
import { WEBSITE_BASE_URL } from "../../utils/config";
import UITextEditor from "../../ui/uitexteditor/UITextEditor";
import {
  deleteMenu,
  editMenu,
  getMenu,
  insertMenu,
} from "../../redux/thunks/menuThunk";
import { Link } from "react-router-dom";
import UICheckbox from "../../ui/uicheckbox/UICheckbox";
import Loader from "../../components/loader/Loader";
import Auth from "../../components/Auth";

export const ListMenu = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(null);
  const [state, setState] = useState({
    name: "",
    image: "",
    price: "",
    discount: "",
    quantity: "",
    duration: "",
    category: "",
    isFeatured: "",
    subcategory: "",
    description: undefined,
  });
  const [error, setError] = useState({
    name: "",
    price: "",
    discount: "",
    quantity: "",
    duration: "",
    category: "",
    subcategory: "",
    description: "",
  });

  const [image, setImage] = useState([]);
  const handleInputField = (e) => {
    const name = e.target.name;
    const type = e.target.type;
    const required = e.target.required;
    const value = parseInputType(type, e);

    const valid = value !== null && value !== undefined && value !== "";
    if (type === "file") {
      setImage(value);
      return;
    }

    setState((prev) => ({ ...prev, [name]: value ?? undefined }));

    required &&
      valid &&
      setError((prev) => ({
        ...prev,
        [name]: value ? "" : "Field is required",
      }));
  };

  const category = useSelector((state) => state.categoryState);
  const menu = useSelector((state) => state.menuState);
  const auth = useSelector((state) => state.authState);
  useEffect(() => {
    auth.token &&
      dispatch(
        getCategory({
          token: auth.token,
        })
      );
  }, [dispatch, auth.token]);

  useEffect(() => {
    auth.token &&
      dispatch(
        getMenu({
          token: auth.token,
        })
      );
  }, [dispatch, auth.token]);

  useEffect(() => {
    auth.token &&
      state.category !== "" &&
      dispatch(
        getSubCategoryByCategory({ token: auth.token, id: state.category })
      );
  }, [dispatch, auth.token, state.category]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Name",
        accessor: (row) => row.name,
        Cell: ({ value }) => (
          <div style={{ textTransform: "capitalize" }}>{value}</div>
        ),
      },
      // {
      //   Header: "Image",
      //   accessor: (row) => (
      //     <div className="tableimage">
      //       <Link
      //         to={`${WEBSITE_BASE_URL}/public/category/${row.image}`}
      //         target="_blank"
      //       >
      //         <img
      //           src={
      //             row?.image
      //               ? `${WEBSITE_BASE_URL}/public/food/${row.image}`
      //               : NO_IMAGE
      //           }
      //           alt="menuimg"
      //         />
      //       </Link>
      //     </div>
      //   ),
      // },
      {
        Header: "Price",
        accessor: (row) => row.price + " Kr",
      },
      {
        Header: "Duration",
        accessor: (row) => row.duration,
      },
      {
        Header: "Category",
        accessor: (row) => row.category?.name + " < " + row.subCategory.name,
      },

      {
        Header: "Created At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        headerStyle: {
          width: "150px",
        },
      },
      {
        Header: "Updated At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        headerStyle: {
          width: "150px",
        },
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <UIButton
              label={<i className="fa-regular fa-pen"></i>}
              type="secondary"
              onClick={() => handleEdit(row)}
            />

            <UIButton
              label={<i className="fa-regular fa-trash"></i>}
              type="error"
              onClick={() => handleDelete(row)}
            />
          </div>
        ),
        headerStyle: {
          width: "100px",
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => {
        if (!menu?.data) return [];
        return [...menu.data].sort(
          (a, b) => new Date(b.updated) - new Date(a.updated)
        );
      }, [menu?.data]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );
  const handleDelete = (item) => setShowDeleteModal(item);
  const resetDelete = () => setShowDeleteModal(null);
  const handleEdit = (item) => {
    setShowEditModal(item);
    setState({
      name: item.name,
      image: item.image,
      price: item.price,
      discount: item.discount,
      quantity: item.quantity,
      duration: item.duration,
      category: item.category._id,
      subcategory: item.subCategory._id,
      description: item.description,
      isFeatured: item.isFeatured,
    });
  };

  const resetEdit = () => {
    setShowEditModal(null);
    setState({
      name: "",
      image: "",
      price: "",
      discount: "",
      quantity: "",
      duration: "",
      category: "",
      subcategory: "",
      description: undefined,
      isFeatured: false,
    });
  };

  const handleAddSubmit = () => {
    try {
      const {
        name,
        price,

        category,
        subcategory,
        description,
      } = state;
      if (!name || !price || !category || !subcategory || !description) {
        const errors = {};
        if (!name) errors.name = "Name is required";
        if (!price) errors.price = "Price is required";

        if (!category) errors.category = "Category is required";
        if (!subcategory) errors.subcategory = "Sub Category is required";
        if (!description) errors.description = "Description is required";
        setError(errors);
        errorToast("Please verify indicated fields");
        return;
      }

      const formData = new FormData();
      formData.append("name", state.name);
      formData.append("price", state.price);
      formData.append("duration", state.duration);
      formData.append("categoryId", state.category);
      formData.append("subCategoryId", state.subcategory);

      formData.append("discount", state.discount !== "" ? state.discount : "0");

      formData.append("quantity", state.quantity);
      formData.append("description", state.description);
      if (image?.length > 0) {
        for (let i = 0; i < image.length; i++) {
          formData.append("image", image[i]);
        }
      }
      auth.token &&
        dispatch(
          insertMenu({
            data: formData,
            token: auth.token,
            callback: successInsert,
          })
        );
    } catch (error) {
      errorToast("Something went wrong with validation");
    }
  };

  const successInsert = () => {
    setState({
      name: "",
      image: "",
      price: "",
      discount: "",
      quantity: "",
      duration: "",
      category: "",
      subcategory: "",
      description: "",
    });
    setImage(null);
    setShowInsertModal(false);
    successToast("Menu Inserted");
  };
  const handleEditSubmit = () => {
    try {
      const {
        name,
        price,

        category,
        subcategory,
        description,
      } = state;
      if (!name || !price || !category || !subcategory || !description) {
        const errors = {};
        if (!name) errors.name = "Name is required";
        if (!price) errors.price = "Price is required";
        if (!category) errors.category = "Category is required";
        if (!subcategory) errors.subcategory = "Sub Category is required";
        if (!description) errors.description = "Description is required";
        setError(errors);
        errorToast("Please verify indicated fields");
        return;
      }

      const formData = new FormData();
      formData.append("name", state.name);
      formData.append("price", state.price);
      formData.append("duration", state.duration);
      formData.append("categoryId", state.category);
      formData.append("subCategoryId", state.subcategory);

      formData.append("discount", state.discount !== "" ? state.discount : "0");

      formData.append("quantity", state.quantity);
      formData.append("isFeatured", state.isFeatured);
      formData.append("description", state.description);
      if (image?.length > 0) {
        for (let i = 0; i < image.length; i++) {
          formData.append("image", image[i]);
        }
      }
      showEditModal &&
        auth.token &&
        dispatch(
          editMenu({
            id: showEditModal?._id,
            data: formData,
            token: auth.token,
            callback: successEdit,
          })
        );
    } catch (error) {
      errorToast("Something went wrong with validation");
    }
  };

  const successEdit = () => {
    setState({
      name: "",
      image: "",
      price: "",
      discount: "",
      quantity: "",
      duration: "",
      category: "",
      subcategory: "",
      description: "",
    });
    setImage(null);
    setShowEditModal(false);
    successToast("Menu Edited");
  };

  const deleteData = () =>
    showDeleteModal &&
    auth.token &&
    dispatch(
      deleteMenu({
        id: showDeleteModal._id,
        token: auth.token,
        callback: () => {
          setShowDeleteModal(null);
          successToast("Menu Deleted");
        },
      })
    );

  return (
    <Auth>
      {menu.loading && <Loader />}
      <div className="framecontainer">
        <Sidebar />
        <div className="framecontainer-content">
          <div className="content-wrapper">
            <Navbar name="Menu" showBars />
            <div className="listpage">
              <div className="listpage-textlink">
                <div className="listpage-textlink--text">
                  <h1>Menu List</h1>
                  <h2>
                    <span>
                      <i className="fa-regular fa-memo-circle-check"></i>
                    </span>
                    &nbsp;/ Menu Management
                  </h2>
                </div>
                <div className="listpage-textlink--addsetting">
                  <UIButton
                    label={
                      <span>
                        <i className="fa-regular fa-plus"></i> Create
                      </span>
                    }
                    type="primary duotone"
                    onClick={() => setShowInsertModal(true)}
                  />
                </div>
              </div>

              <div className="listpage-searchentry">
                <div className="listpage-searchentry--search">
                  <UIInput
                    type="search"
                    placeholder="Search here..."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setGlobalFilter(e.target.value);
                    }}
                  />
                  <i className="fa-regular fa-magnifying-glass"></i>
                </div>
                <UISelect
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  options={[
                    { value: "5" },
                    { value: "10" },
                    { value: "25" },
                    { value: "50" },
                    { value: menu?.data?.length, displayValue: "All" },
                  ]}
                  style={{ width: "7rem" }}
                />
              </div>

              <div className="listpage-tablesection">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            style={column.headerStyle}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length} className="notfound">
                          Data not found.
                        </td>
                      </tr>
                    ) : (
                      page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell, cellIndex) => (
                              <td {...cell.getCellProps()}>
                                {cell.column.Header === "S.N"
                                  ? rowIndex + 1 + pageIndex * pageSize
                                  : cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {pageSize !== menu?.data?.length && (
                  <div className="listpage-tablesection--pagination">
                    <button
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>
                    <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </button>
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                      {">"}
                    </button>
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInsertModal && (
        <UIModal
          onClose={() => setShowInsertModal((prev) => !prev)}
          showAnimation
        >
          <div className="modalcontent-insert">
            <h2 className="modalcontent-insert--heading">Add Food Menu</h2>

            <div className="modalcontent-insert--form">
              <div className="modalcontent-insert--form__left">
                <UIInput
                  id="name"
                  label="Name"
                  placeholder="eg. Grill Chicken"
                  name="name"
                  type="text"
                  isRequired
                  onChange={handleInputField}
                  value={state.name}
                  error={error.name}
                />
                <UIInput
                  id="price"
                  label="Price"
                  placeholder="eg. 1000"
                  name="price"
                  type="text"
                  isRequired
                  onChange={handleInputField}
                  value={state.price}
                  error={error.price}
                />
                <UIInput
                  id="discount"
                  label="Discount"
                  placeholder="eg. 50"
                  name="discount"
                  type="text"
                  onChange={handleInputField}
                  value={state.discount}
                  error={error.discount}
                />
                <UIInput
                  id="quantity"
                  label="Quantity"
                  placeholder="eg. 100"
                  name="quantity"
                  type="text"
                  onChange={handleInputField}
                  value={state.quantity}
                  error={error.quantity}
                />

                <UIInput
                  id="duration"
                  label="Preparation Time"
                  placeholder="eg. 30 min"
                  name="duration"
                  type="text"
                  onChange={handleInputField}
                  value={state.duration}
                  error={error.duration}
                />
                <UISelect
                  label="Category"
                  name="category"
                  id="category"
                  isRequired
                  style={{ width: "100%" }}
                  placeholder={"Select Any Category"}
                  options={[
                    ...(category?.data?.map((option) => ({
                      value: option?._id,
                      displayValue: option?.name,
                    })) ?? []),
                  ]}
                  defaultValue={state.category}
                  key={Math.random()}
                  onChange={handleInputField}
                  error={error.category}
                />
                <UISelect
                  label="Sub Category"
                  name="subcategory"
                  id="subcategory"
                  isRequired
                  style={{ width: "100%" }}
                  placeholder={"Select Any Sub Category"}
                  options={[
                    ...(category?.subCategoryData?.map((option) => ({
                      value: option?._id,
                      displayValue: option?.name,
                    })) ?? []),
                  ]}
                  defaultValue={state.subcategory}
                  key={Math.random()}
                  onChange={handleInputField}
                  error={error.subcategory}
                />
              </div>
              <div className="modalcontent-insert--form__right">
                <UITextEditor
                  id="description"
                  label="Description"
                  formatedEditorConfig={{
                    readonly: false,
                    height: 430,
                  }}
                  name="description"
                  isRequired
                  onChange={handleInputField}
                  defaultValue={state.description}
                  error={error.description}
                />
              </div>
            </div>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              >
                <UIFileInput
                  id="image"
                  label="Images"
                  isRequired
                  multiple
                  style={{ width: "25rem", height: "25rem" }}
                  inputStyle={{ width: "100%", height: "25rem" }}
                  onChange={handleInputField}
                />
              </div>
            </div>

            <div className="modalcontent-insert--actions">
              <UIButton
                label="Save"
                type="primary"
                onClick={handleAddSubmit}
                style={{ minWidth: "13rem" }}
              />
            </div>
          </div>
        </UIModal>
      )}

      {showEditModal && (
        <UIModal onClose={resetEdit} showAnimation>
          <div className="modalcontent-insert">
            <h2 className="modalcontent-insert--heading">Edit Food menu</h2>

            <div className="modalcontent-insert--form">
              <div className="modalcontent-insert--form__left">
                <UIInput
                  id="name"
                  label="Name"
                  placeholder="eg. Grill Chicken"
                  name="name"
                  type="text"
                  isRequired
                  onChange={handleInputField}
                  value={state.name}
                  error={error.name}
                />
                <UIInput
                  id="price"
                  label="Price"
                  placeholder="eg. 1000"
                  name="price"
                  type="text"
                  isRequired
                  onChange={handleInputField}
                  value={state.price}
                  error={error.price}
                />
                <UIInput
                  id="discount"
                  label="Discount"
                  placeholder="eg. 50"
                  name="discount"
                  type="text"
                  onChange={handleInputField}
                  value={state.discount}
                  error={error.discount}
                />
                <UIInput
                  id="quantity"
                  label="Quantity"
                  placeholder="eg. 100"
                  name="quantity"
                  type="text"
                  onChange={handleInputField}
                  value={state.quantity}
                  error={error.quantity}
                />

                <UIInput
                  id="duration"
                  label="Preparation Time"
                  placeholder="eg. 30 min"
                  name="duration"
                  type="text"
                  onChange={handleInputField}
                  value={state.duration}
                  error={error.duration}
                />
                <UISelect
                  label="Category"
                  name="category"
                  id="category"
                  isRequired
                  style={{ width: "100%" }}
                  placeholder={"Select Any Category"}
                  options={[
                    ...(category?.data?.map((option) => ({
                      value: option?._id,
                      displayValue: option?.name,
                    })) ?? []),
                  ]}
                  defaultValue={state.category}
                  key={Math.random()}
                  onChange={handleInputField}
                  error={error.category}
                />
                <UISelect
                  label="Sub Category"
                  name="subcategory"
                  id="subcategory"
                  isRequired
                  style={{ width: "100%" }}
                  placeholder={"Select Any Sub Category"}
                  options={[
                    ...(category?.subCategoryData?.map((option) => ({
                      value: option?._id,
                      displayValue: option?.name,
                    })) ?? []),
                  ]}
                  defaultValue={state.subcategory}
                  key={Math.random()}
                  onChange={handleInputField}
                  error={error.subcategory}
                />
              </div>
              <div className="modalcontent-insert--form__right">
                <UITextEditor
                  id="description"
                  label="Description"
                  formatedEditorConfig={{
                    readonly: false,
                    height: 380,
                  }}
                  name="description"
                  isRequired
                  onChange={handleInputField}
                  defaultValue={state.description}
                  error={error.description}
                />
                <UICheckbox
                  label="Is Featured"
                  name="isFeatured"
                  id="isFeaturd"
                  defaultChecked={state.isFeatured}
                  error={error.isFeatured}
                  onChange={handleInputField}
                />
              </div>
            </div>
            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              >
                <UIFileInput
                  id="image"
                  label="Images"
                  isRequired
                  multiple
                  style={{ width: "25rem", height: "25rem" }}
                  inputStyle={{ width: "100%", height: "25rem" }}
                  onChange={handleInputField}
                />
                {image.length === 0 && (
                  <div style={{ display: "flex", gap: "2.5rem" }}>
                    {state.image &&
                      state.image.split(",").map((value, i) => (
                        <img
                          src={
                            state?.image
                              ? `${WEBSITE_BASE_URL}/public/food/${value} `
                              : NO_IMAGE
                          }
                          alt={"images"}
                          quality={100}
                          width={1000}
                          height={1000}
                          key={i}
                          style={{
                            width: "25rem",
                            height: "25rem",
                            objectFit: "contain",
                          }}
                        />
                      ))}
                  </div>
                )}
              </div>
            </div>

            <div className="modalcontent-insert--actions">
              <UIButton
                label="Save"
                type="primary"
                onClick={handleEditSubmit}
                style={{ minWidth: "13rem" }}
              />
            </div>
          </div>
        </UIModal>
      )}

      {showDeleteModal ? (
        <UIDialogBox
          cancel={resetDelete}
          confirm={() => deleteData()}
          message="Are you sure you want to delete this item?"
          title="Confirm Delete"
        />
      ) : null}
    </Auth>
  );
};
