import { createSlice } from "@reduxjs/toolkit";

import { API_EXPIRE_TIME } from "../../constants/constants";
import { deleteBanner, getBanner, insertBanner } from "../thunks/bannerThunk";

const initialState = {
  data: [],
  loading: false,
  expires: null,
  error: null,
  status: "All",
  disableConfig: false,
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //!Fetch
      .addCase(getBanner.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getBanner.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getBanner.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch banners data";
        state.expires = null;
      })
      .addCase(insertBanner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [action.payload, ...state.data];
        state.error = null;
      })
      .addCase(insertBanner.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to insert banner data";
      })
      .addCase(deleteBanner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.loading = false;

        state.data = state.data.filter((i) => i._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteBanner.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to delete banner data";
      });
  },
});

// export const { setStatus } = bannerSlice.actions;
export default bannerSlice.reducer;
