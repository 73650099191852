import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet } from "../../utils/axios";

export const getTableBooking = createAsyncThunk(
  "getTableBooking",
  async ({ token }) => {
    try {
      const response = await doGet(`/book-tables`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteTableBooking = createAsyncThunk(
  "deleteTableBooking",
  async ({ id, token, callback }) => {
    try {
      await doDelete(`/book-tables/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return id;
    } catch (error) {
      throw error;
    }
  }
);
