import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { getBanner } from "../../redux/thunks/bannerThunk";
import { getCategory } from "../../redux/thunks/categoryThunk";
import { getUser } from "../../redux/thunks/userThunk";
import { getMenu } from "../../redux/thunks/menuThunk";
import { getQuery } from "../../redux/thunks/queryThunk";
import { getTableBooking } from "../../redux/thunks/tableBookingThunk";
import { getBlog } from "../../redux/thunks/blogThunk";
import { getGallery } from "../../redux/thunks/galleryThunk";
import { getFAQ } from "../../redux/thunks/faqThunk";
import { getOrders } from "../../redux/thunks/ordersThunk";
import { getTestimonial } from "../../redux/thunks/testimonialThunk";
import { getTotalSubCategory } from "../../redux/thunks/subCategoryThunk";
import "../../style/main.css";
import Auth from "../../components/Auth";
// import Analytics from "../../components/charts/Analytics";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authState);
  const banner = useSelector((state) => state.bannerState);
  const category = useSelector((state) => state.categoryState);
  const menu = useSelector((state) => state.menuState);
  const query = useSelector((state) => state.queryState);
  const tableBooking = useSelector((state) => state.tableBookingState);
  const blog = useSelector((state) => state.blogState);
  const gallery = useSelector((state) => state.galleryState);
  const faq = useSelector((state) => state.faqState);
  const user = useSelector((state) => state.userState);
  const orders = useSelector((state) => state.ordersState);
  const testimonial = useSelector((state) => state.testimonialState);
  const subCategory = useSelector((state) => state.subCategoryState);

  useEffect(() => {
    if (auth.token) {
      dispatch(
        getBanner({
          token: auth.token,
        })
      );
      dispatch(
        getCategory({
          token: auth.token,
        })
      );
      dispatch(
        getUser({
          token: auth.token,
        })
      );
      dispatch(
        getMenu({
          token: auth.token,
        })
      );
      dispatch(
        getQuery({
          token: auth.token,
        })
      );
      dispatch(
        getTableBooking({
          token: auth.token,
        })
      );
      dispatch(
        getBlog({
          token: auth.token,
        })
      );
      dispatch(
        getGallery({
          token: auth.token,
        })
      );
      dispatch(
        getFAQ({
          token: auth.token,
        })
      );
      dispatch(
        getOrders({
          token: auth.token,
        })
      );
      dispatch(
        getTestimonial({
          token: auth.token,
        })
      );
      dispatch(
        getTotalSubCategory({
          token: auth.token,
        })
      );
    }
  }, [dispatch, auth.token]);

  return (
    <Auth>
      <div className="framecontainer">
        <Sidebar />

        <div className="framecontainer-content">
          <div className="content-wrapper">
            <Navbar name="Dashboard" showBars />
            <div className="dashboard">
              <div className="dashboard-top">
                <h1>Dashboard</h1>
                <h2>
                  <span>
                    <i className="fa-regular fa-house"></i>
                  </span>
                  &nbsp;/ Dashboard
                </h2>
              </div>

              <div className="dashboard-analytics">
                <Link to="/banner" className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-flag-pennant"></i>
                  </div>
                  <h2>Banners</h2>
                  <h1>{banner?.data?.length}</h1>
                </Link>
                <Link to="/user" className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-users"></i>
                  </div>
                  <h2>Users</h2>
                  <h1>{user?.data?.length}</h1>
                </Link>

                <Link to="/category" className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-list-tree"></i>
                  </div>
                  <h2>Categories</h2>
                  <h1>{category?.data?.length}</h1>
                </Link>

                <div className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-list-tree"></i>
                  </div>
                  <h2>Sub Categories</h2>
                  <h1>{subCategory?.total.length}</h1>
                </div>

                <Link to="/menu" className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-memo-circle-check"></i>
                  </div>
                  <h2>Menu</h2>
                  <h1>{menu?.data?.length}</h1>
                </Link>
                <Link to="/gallery" className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-images"></i>
                  </div>
                  <h2>Gallery</h2>
                  <h1>{gallery?.data?.length}</h1>
                </Link>
                <Link to="/table-booking" className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-chair"></i>
                  </div>
                  <h2>Table Booking</h2>
                  <h1>{tableBooking?.data?.length}</h1>
                </Link>
                <Link to="/faq" className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-person-circle-question"></i>
                  </div>
                  <h2>FAQ</h2>
                  <h1>{faq?.data?.length}</h1>
                </Link>
                <Link to="/orders" className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-cart-plus"></i>
                  </div>
                  <h2>Orders</h2>
                  <h1>{orders?.data?.length}</h1>
                </Link>
                <Link to="/blog" className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-blog"></i>
                  </div>
                  <h2>Blog</h2>
                  <h1>{blog?.data?.length}</h1>
                </Link>
                <Link to="/query" className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-circle-question"></i>
                  </div>
                  <h2>Query</h2>
                  <h1>{query?.data?.length}</h1>
                </Link>
                <Link to="/testimonial" className="dashboard-analytics--card">
                  <div className="dashboard-analytics--card_icon">
                    <i className="fa-regular fa-head-side-heart"></i>
                  </div>
                  <h2>Testimonial</h2>
                  <h1>{testimonial?.data?.length}</h1>
                </Link>
              </div>
            </div>
          </div>

          {/* <Analytics /> */}
        </div>
      </div>
    </Auth>
  );
};
