import { createSlice } from "@reduxjs/toolkit";

import { API_EXPIRE_TIME } from "../../constants/constants";
import { deleteBlog, editBlog, getBlog, insertBlog } from "../thunks/blogThunk";
const initialState = {
  data: [],
  loading: false,
  expires: null,
  error: null,
  status: "All",
  disableConfig: false,
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //!Fetch
      .addCase(getBlog.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getBlog.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getBlog.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch blogs data";
        state.expires = null;
      })
      .addCase(insertBlog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [action.payload, ...state.data];
        state.error = null;
      })
      .addCase(insertBlog.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to insert blog data";
      })
      .addCase(deleteBlog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBlog.fulfilled, (state, action) => {
        state.loading = false;

        state.data = state.data.filter((i) => i._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteBlog.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to delete blog data";
      })
      //!Edit
      .addCase(editBlog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.map((i) =>
          i._id === action.payload._id ? { ...i, ...action.payload } : i
        );
        state.error = null;
      })

      .addCase(editBlog.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to edit blog data";
      });
  },
});

// export const { setStatus } = blogSlice.actions;
export default blogSlice.reducer;
