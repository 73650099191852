import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPatch, doPost } from "../../utils/axios";

export const getCategory = createAsyncThunk(
  "getCategory",
  async ({ token }) => {
    try {
      const response = await doGet(`/categories`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getSubCategoryByCategory = createAsyncThunk(
  "getSubCategoryByCategory",
  async ({ token, id }) => {
    try {
      const response = await doGet(`/subcategories/category/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const insertCategory = createAsyncThunk(
  "insertCategory",
  async ({ data, token, callback }) => {
    try {
      const response = await doPost(`/categories`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const editCategory = createAsyncThunk(
  "editCategory",
  async ({ data, token, id, callback }) => {
    try {
      const response = await doPatch(`/categories/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async ({ id, token, callback }) => {
    try {
      await doDelete(`/categories/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return id;
    } catch (error) {
      throw error;
    }
  }
);
