import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import UIButton from "../../ui/uibutton/UIButton";
import UISelect from "../../ui/uiselect/UISelect";
import UIDialogBox from "../../ui/uidialogbox/UIDialogBox";
import { UIInput } from "../../ui/uiinput/UIInput";
import { NO_IMAGE } from "../../constants/images";
import UIModal from "../../ui/uimodal/UIModal";
import UIFileInput from "../../ui/uifileinput/UIFileInput";
import { parseInputType } from "../../utils/helper";
import { errorToast, successToast } from "../../utils/toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGallery,
  getGallery,
  insertGallery,
} from "../../redux/thunks/galleryThunk";
import { WEBSITE_BASE_URL } from "../../utils/config";
import { Link } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import Auth from "../../components/Auth";

export const ListGallery = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [image, setImage] = useState([]);
  const handleInputField = (e) => {
    const type = e.target.type;
    const value = parseInputType(type, e);
    if (type === "file") {
      setImage(value);
      return;
    }
  };

  const gallery = useSelector((state) => state.galleryState);
  const auth = useSelector((state) => state.authState);
  useEffect(() => {
    auth.token &&
      dispatch(
        getGallery({
          token: auth.token,
        })
      );
  }, [dispatch, auth.token]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Image",
        accessor: (row) => (
          <div className="tableimage">
            <Link
              to={`${WEBSITE_BASE_URL}/public/gallery/${row.image}`}
              target="_blank"
            >
              <img
                src={
                  row.image
                    ? `${WEBSITE_BASE_URL}/public/gallery/${row.image}`
                    : NO_IMAGE
                }
                alt="galleryimg"
              />
            </Link>
          </div>
        ),
      },
      {
        Header: "Created At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        // headerStyle: {
        //   width: "200px",
        // },
      },
      // {
      //   Header: "Updated At",
      //   accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
      //   headerStyle: {
      //     width: "200px",
      //   },
      // },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <UIButton
              label={<i className="fa-regular fa-trash"></i>}
              type="error"
              onClick={() => handleDelete(row)}
            />
          </div>
        ),
        // headerStyle: {
        //   width: "100px",
        // },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => {
        if (!gallery?.data) return [];
        return [...gallery.data].sort(
          (a, b) => new Date(b.updated) - new Date(a.updated)
        );
      }, [gallery?.data]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );
  const handleDelete = (item) => setShowDeleteModal(item);
  const resetDelete = () => setShowDeleteModal(null);

  const handleAddSubmit = () => {
    try {
      const formData = new FormData();
      if (image.length > 0) {
        for (let i = 0; i < image.length; i++) {
          formData.append("image", image[i]);
        }
      }
      auth.token &&
        dispatch(
          insertGallery({
            data: formData,
            token: auth.token,
            callback: successInsert,
          })
        );
    } catch (error) {
      errorToast("Something went wrong with validation");
    }
  };

  const successInsert = () => {
    dispatch(getGallery({ token: auth.token }));
    setImage([]);
    setShowInsertModal(false);
    successToast("Gallery Inserted");
  };

  const deleteData = () =>
    showDeleteModal &&
    auth.token &&
    dispatch(
      deleteGallery({
        id: showDeleteModal._id,
        token: auth.token,
        callback: () => {
          setShowDeleteModal(null);
          successToast("Gallery Deleted");
        },
      })
    );
  return (
    <Auth>
      {gallery.loading && <Loader />}
      <div className="framecontainer">
        <Sidebar />
        <div className="framecontainer-content">
          <div className="content-wrapper">
            <Navbar name="Gallery" showBars />
            <div className="listpage">
              <div className="listpage-textlink">
                <div className="listpage-textlink--text">
                  <h1>Gallery List</h1>
                  <h2>
                    <span>
                      <i className="fa-regular fa-images"></i>
                    </span>
                    &nbsp;/ Gallery Management
                  </h2>
                </div>
                <div className="listpage-textlink--addsetting">
                  <UIButton
                    label={
                      <span>
                        <i className="fa-regular fa-plus"></i> Create
                      </span>
                    }
                    type="primary duotone"
                    onClick={() => setShowInsertModal(true)}
                  />
                </div>
              </div>

              <div className="listpage-searchentry">
                <div className="listpage-searchentry--search">
                  <UIInput
                    type="search"
                    placeholder="Search here..."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setGlobalFilter(e.target.value);
                    }}
                  />
                  <i className="fa-regular fa-magnifying-glass"></i>
                </div>
                <UISelect
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  options={[
                    { value: "5" },
                    { value: "10" },
                    { value: "25" },
                    { value: "50" },
                    { value: gallery?.data?.length, displayValue: "All" },
                  ]}
                  style={{ width: "7rem" }}
                />
              </div>

              <div className="listpage-tablesection">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            style={column.headerStyle}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length} className="notfound">
                          Data not found.
                        </td>
                      </tr>
                    ) : (
                      page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell, cellIndex) => (
                              <td {...cell.getCellProps()}>
                                {cell.column.Header === "S.N"
                                  ? rowIndex + 1 + pageIndex * pageSize
                                  : cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {pageSize !== gallery?.data?.length && (
                  <div className="listpage-tablesection--pagination">
                    <button
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>
                    <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </button>
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                      {">"}
                    </button>
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInsertModal && (
        <UIModal
          onClose={() => setShowInsertModal((prev) => !prev)}
          showAnimation
        >
          <div className="modalcontent-insert">
            <h2 className="modalcontent-insert--heading">Add Gallery Images</h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              >
                <UIFileInput
                  style={{ width: "25rem", height: "25rem" }}
                  inputStyle={{ width: "100%", height: "25rem" }}
                  onChange={handleInputField}
                  multiple
                />
              </div>
            </div>

            <div className="modalcontent-insert--actions">
              <UIButton
                label="Save"
                type="primary"
                onClick={handleAddSubmit}
                style={{ minWidth: "13rem" }}
              />
            </div>
          </div>
        </UIModal>
      )}

      {showDeleteModal ? (
        <UIDialogBox
          cancel={resetDelete}
          confirm={() => deleteData()}
          message="Are you sure you want to delete this item?"
          title="Confirm Delete"
        />
      ) : null}
    </Auth>
  );
};
