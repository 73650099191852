import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import UIButton from "../../ui/uibutton/UIButton";
import UISelect from "../../ui/uiselect/UISelect";
import UIDialogBox from "../../ui/uidialogbox/UIDialogBox";
import { UIInput } from "../../ui/uiinput/UIInput";
// import UIModal from "../../ui/uimodal/UIModal";

import { successToast } from "../../utils/toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTableBooking,
  getTableBooking,
} from "../../redux/thunks/tableBookingThunk";
import Loader from "../../components/loader/Loader";
import Auth from "../../components/Auth";

export const ListTableBooking = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [showEditModal, setShowEditModal] = useState(null);

  const tableBooking = useSelector((state) => state.tableBookingState);
  const auth = useSelector((state) => state.authState);
  useEffect(() => {
    auth.token &&
      dispatch(
        getTableBooking({
          token: auth.token,
        })
      );
  }, [dispatch, auth.token]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
        headerStyle: {
          width: "40px",
        },
      },
      {
        Header: "Full Name",
        accessor: (row) => row.firstName + " " + row.lastName,
        Cell: ({ value }) => (
          <div style={{ textTransform: "capitalize" }}>{value}</div>
        ),
      },
      {
        Header: "Phone",
        accessor: (row) => row.phone,
        headerStyle: {
          width: "120px",
        },
      },
      {
        Header: "Date",
        accessor: (row) => moment(row.date).format("MMM Do YYYY"),
        headerStyle: {
          width: "120px",
        },
      },
      {
        Header: "Time",
        accessor: (row) => row.time,
        headerStyle: {
          width: "100px",
        },
      },
      {
        Header: "Message",
        accessor: (row) => row.message,
      },

      {
        Header: "Booked At",
        accessor: (row) => moment(row.updated).format("MMM Do YYYY"),
        headerStyle: {
          width: "150px",
        },
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            {/* <UIButton
              label={<i className="fa-regular fa-eye"></i>}
              type="secondary"
              onClick={() => handleEdit(row)}
            /> */}

            <UIButton
              label={<i className="fa-regular fa-trash"></i>}
              type="error"
              onClick={() => handleDelete(row)}
            />
          </div>
        ),
        headerStyle: {
          width: "100px",
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => {
        if (!tableBooking?.data) return [];
        return [...tableBooking.data].sort(
          (a, b) => new Date(b.updated) - new Date(a.updated)
        );
      }, [tableBooking?.data]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );
  const handleDelete = (item) => setShowDeleteModal(item);
  const resetDelete = () => setShowDeleteModal(null);
  // const handleEdit = (item) => {
  //   setShowEditModal(item);
  // };
  // console.log(showEditModal);
  // const resetEdit = () => setShowEditModal(null);

  const deleteData = () =>
    showDeleteModal &&
    auth.token &&
    dispatch(
      deleteTableBooking({
        id: showDeleteModal._id,
        token: auth.token,
        callback: () => {
          setShowDeleteModal(null);
          successToast("Category Deleted");
        },
      })
    );
  return (
    <Auth>
      {tableBooking.loading && <Loader />}
      <div className="framecontainer">
        <Sidebar />
        <div className="framecontainer-content">
          <div className="content-wrapper">
            <Navbar name="Table Bookings" showBars />
            <div className="listpage">
              <div className="listpage-textlink">
                <div className="listpage-textlink--text">
                  <h1>Table Booking List</h1>
                  <h2>
                    <span>
                      <i className="fa-regular fa-chair"></i>
                    </span>
                    &nbsp;/ Table Booking Management
                  </h2>
                </div>
                <div className="listpage-textlink--addsetting"></div>
              </div>

              <div className="listpage-searchentry">
                <div className="listpage-searchentry--search">
                  <UIInput
                    type="search"
                    placeholder="Search here..."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setGlobalFilter(e.target.value);
                    }}
                  />
                  <i className="fa-regular fa-magnifying-glass"></i>
                </div>
                <UISelect
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  options={[
                    { value: "5" },
                    { value: "10" },
                    { value: "25" },
                    { value: "50" },
                    { value: tableBooking?.data?.length, displayValue: "All" },
                  ]}
                  style={{ width: "7rem" }}
                />
              </div>

              <div className="listpage-tablesection">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            style={column.headerStyle}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length} className="notfound">
                          Data not found.
                        </td>
                      </tr>
                    ) : (
                      page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell, cellIndex) => (
                              <td {...cell.getCellProps()}>
                                {cell.column.Header === "S.N"
                                  ? rowIndex + 1 + pageIndex * pageSize
                                  : cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {pageSize !== tableBooking?.data?.length && (
                  <div className="listpage-tablesection--pagination">
                    <button
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>
                    <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </button>
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                      {">"}
                    </button>
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {showEditModal && (
        <UIModal onClose={resetEdit} showAnimation>
          <div className="modalcontent-insert" style={{ width: "500px" }}>
            <h2 className="modalcontent-insert--heading">Edit Category</h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              ></div>
            </div>
          </div>
        </UIModal>
      )} */}

      {showDeleteModal ? (
        <UIDialogBox
          cancel={resetDelete}
          confirm={() => deleteData()}
          message="Are you sure you want to delete this item?"
          title="Confirm Delete"
        />
      ) : null}
    </Auth>
  );
};
