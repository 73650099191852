export default function UICheckbox({
  id,
  label,
  name,
  isRequired,
  style,
  onClick,
  onChange,
  error,
  defaultChecked = false,
  instruction,
}) {
  return (
    <div className="checkbox" style={style}>
      <div className="checkbox-wrapper">
        {label ? (
          <label htmlFor={id ? id : ""} className="checkbox-label">
            {label} {isRequired ? "*" : ""}
          </label>
        ) : null}
        <div className={`checkbox-marker ${error ? "error" : ""}`}>
          <input
            type="checkbox"
            id={id}
            name={name}
            onClick={(e) => onClick?.(e)}
            onChange={(e) => onChange?.(e)}
            checked={defaultChecked}
            required={isRequired}
          />
        </div>
      </div>

      {error ? <span className="checkbox-error">{error}</span> : null}

      {instruction ? (
        <p className="checkbox-instruction">{instruction}</p>
      ) : null}
    </div>
  );
}
