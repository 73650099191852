import { createSlice } from "@reduxjs/toolkit";

import { API_EXPIRE_TIME } from "../../constants/constants";
import {
  deleteTableBooking,
  getTableBooking,
} from "../thunks/tableBookingThunk";

const initialState = {
  data: [],
  loading: false,
  expires: null,
  error: null,
  status: "All",
  disableConfig: false,
};

const tableBookingSlice = createSlice({
  name: "tableBooking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //!Fetch
      .addCase(getTableBooking.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getTableBooking.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getTableBooking.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch tableBookings data";
        state.expires = null;
      })
      .addCase(deleteTableBooking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTableBooking.fulfilled, (state, action) => {
        state.loading = false;

        state.data = state.data.filter((i) => i._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteTableBooking.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to delete category data";
      });
  },
});

// export const { setStatus } = tableBookingSlice.actions;
export default tableBookingSlice.reducer;
