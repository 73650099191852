import { createSlice } from "@reduxjs/toolkit";

import { API_EXPIRE_TIME } from "../../constants/constants";
import { blockUser, getUser } from "../thunks/userThunk";

const initialState = {
  data: [],
  loading: false,
  expires: null,
  error: null,
  status: "All",
  disableConfig: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //!Fetch
      .addCase(getUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getUser.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch users data";
        state.expires = null;
      });

    // //!Edit
    // .addCase(blockUser.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // })
    // .addCase(blockUser.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.data = state.data.map((i) =>
    //     i._id === action.payload._id
    //       ? {
    //           ...action.payload,
    //         }
    //       : i
    //   );
    //   state.error = null;
    // })

    // .addCase(blockUser.rejected, (state) => {
    //   state.loading = false;
    //   state.error = "Failed to edit user data";
    // });
  },
});

// export const { setStatus } = userSlice.actions;
export default userSlice.reducer;
