import { createAsyncThunk } from "@reduxjs/toolkit";
import { doGet, doPatch } from "../../utils/axios";

export const getOrders = createAsyncThunk("getOrders", async ({ token }) => {
  try {
    const response = await doGet(`/orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
});

export const getOrderDetail = createAsyncThunk(
  "getOrderDetail",
  async ({ token, id }) => {
    try {
      const response = await doGet(`/orderitems/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updatePaymentStatus = createAsyncThunk(
  "updatePaymentStatus",
  async ({ token, data, id, callback }) => {
    try {
      const response = await doPatch(`/orders/cod/updatepayment/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updateDeliveryStatus = createAsyncThunk(
  "updateDeliveryStatus",
  async ({ token, data, id, callback }) => {
    try {
      const response = await doPatch(`/deliverystatus/orders/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);
