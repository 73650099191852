import { toggleMenu } from "../../redux/slice/systemSlice";
import { useDispatch, useSelector } from "react-redux";
import { DUMMY_PROFILE } from "../../constants/images";
import { logout } from "../../redux/slice/authSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import UIModal from "../../ui/uimodal/UIModal";
import { UIInput } from "../../ui/uiinput/UIInput";
import UIButton from "../../ui/uibutton/UIButton";
import { errorToast, successToast } from "../../utils/toastify";
import { parseInputType } from "../../utils/helper";
import { changePassword } from "../../redux/thunks/passwordThunk";

export default function Navbar({ name, showBars, back }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleToggleBar = (e) => dispatch(toggleMenu());
  const handleLogout = (e) => {
    dispatch(logout());
    navigate("/", { replace: true });
  };
  const auth = useSelector((state) => state.authState);
  const [state, setState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const handleInputField = (e) => {
    const name = e.target.name;
    const type = e.target.type;
    const required = e.target.required;
    const value = parseInputType(type, e);

    const valid = value !== null && value !== undefined && value !== "";

    setState((prev) => ({ ...prev, [name]: value ?? undefined }));

    required &&
      valid &&
      setError((prev) => ({
        ...prev,
        [name]: value ? "" : "Field is required",
      }));
  };
  const handleSubmit = () => {
    try {
      const { oldPassword, newPassword, confirmPassword } = state;
      if (!oldPassword || !newPassword || !confirmPassword) {
        const errors = {};
        if (!oldPassword) errors.oldPassword = "Old Password is required";
        if (!newPassword) errors.newPassword = "New Password is required";
        if (!confirmPassword)
          errors.confirmPassword = "Confirm Password is required";
        setError(errors);
        errorToast("Please verify indicated fields");
        return;
      }
      if (newPassword !== confirmPassword) {
        errorToast("Password Mismatched");
        return;
      }

      auth.token &&
        dispatch(
          changePassword({
            data: state,
            id: auth.id,
            token: auth.token,
            callback: successInsert,
          })
        );
    } catch (error) {
      errorToast("Something went wrong with validation");
    }
  };

  const successInsert = () => {
    setState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

    setShowModal(false);
    successToast("Password Changed");
    handleLogout();
  };
  return (
    <>
      <div className="navbar">
        <div className="navbar-left">
          {back ? (
            <i
              className="fa-regular fa-chevron-left navbar-left--icon__back"
              onClick={() => back()}
            ></i>
          ) : null}
          {showBars ? (
            <i
              className={`navbar-left--icon__bar fa-light fa-bars`}
              onClick={handleToggleBar}
            ></i>
          ) : null}
          <h2 className="navbar-left--text">{name}</h2>
        </div>
        <div className="navbar-right">
          <div
            onClick={() => setShowModal((prev) => !prev)}
            className="navbar-right--item navbar-right--item__logout"
          >
            <i className="fa-regular fa-key"></i>
          </div>
          <div
            onClick={handleLogout}
            className="navbar-right--item navbar-right--item__logout"
          >
            <i className="fa-regular fa-arrow-right-from-bracket"></i>
          </div>
          <div className="navbar-right--profile">
            <img src={DUMMY_PROFILE} alt="User profile" />
          </div>
        </div>
      </div>
      {showModal && (
        <UIModal onClose={() => setShowModal((prev) => !prev)} showAnimation>
          <div className="modalcontent-insert" style={{ width: "500px" }}>
            <h2 className="modalcontent-insert--heading">Change Password</h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              >
                <UIInput
                  id="oldPassword"
                  label="Old Password"
                  placeholder="eg. ***********"
                  name="oldPassword"
                  type="password"
                  isRequired
                  onChange={handleInputField}
                  value={state.oldPassword}
                  error={error.oldPassword}
                />
                <UIInput
                  id="newPassword"
                  label="New Password"
                  placeholder="eg. ***********"
                  name="newPassword"
                  type="password"
                  isRequired
                  onChange={handleInputField}
                  value={state.newPassword}
                  error={error.newPassword}
                />
                <UIInput
                  id="confirmPassword"
                  label="Confirm Password"
                  placeholder="eg. ***********"
                  name="confirmPassword"
                  type="password"
                  isRequired
                  onChange={handleInputField}
                  value={state.confirmPassword}
                  error={error.confirmPassword}
                />
              </div>
            </div>

            <div className="modalcontent-insert--actions">
              <UIButton
                label="Save"
                type="primary"
                onClick={handleSubmit}
                style={{ minWidth: "13rem" }}
              />
            </div>
          </div>
        </UIModal>
      )}
    </>
  );
}
