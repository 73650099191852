import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import UIButton from "../../ui/uibutton/UIButton";
import UISelect from "../../ui/uiselect/UISelect";
import UIDialogBox from "../../ui/uidialogbox/UIDialogBox";
import { UIInput } from "../../ui/uiinput/UIInput";
import { NO_IMAGE } from "../../constants/images";
import UIModal from "../../ui/uimodal/UIModal";
import UIFileInput from "../../ui/uifileinput/UIFileInput";
import { parseInputType } from "../../utils/helper";
import { errorToast, successToast } from "../../utils/toastify";
import { useDispatch, useSelector } from "react-redux";
import { WEBSITE_BASE_URL } from "../../utils/config";
import { useParams } from "react-router-dom";
import {
  deleteSubCategory,
  editSubCategory,
  getSubCategory,
  insertSubCategory,
} from "../../redux/thunks/subCategoryThunk";
import Loader from "../../components/loader/Loader";
import Auth from "../../components/Auth";

export const ListSubCategory = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(null);
  const [state, setState] = useState({
    name: "",
    image: "",
  });
  const [error, setError] = useState({
    name: "",
  });

  const [image, setImage] = useState("");
  const handleInputField = (e) => {
    const name = e.target.name;
    const type = e.target.type;
    const required = e.target.required;
    const value = parseInputType(type, e);

    const valid = value !== null && value !== undefined && value !== "";
    if (type === "file") {
      const file = e.target.files[0];
      setImage(file);
      return;
    }

    setState((prev) => ({ ...prev, [name]: value ?? undefined }));

    required &&
      valid &&
      setError((prev) => ({
        ...prev,
        [name]: value ? "" : "Field is required",
      }));
  };

  const subCategory = useSelector((state) => state.subCategoryState);
  const auth = useSelector((state) => state.authState);
  useEffect(() => {
    auth.token &&
      id &&
      dispatch(
        getSubCategory({
          token: auth.token,
          id: id,
        })
      );
  }, [dispatch, auth.token, id]);
  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Name",
        accessor: (row) => row.name,
        Cell: ({ value }) => (
          <div style={{ textTransform: "capitalize" }}>{value}</div>
        ),
      },
      {
        Header: "Image",
        accessor: (row) => (
          <div className="tableimage">
            <img
              src={
                row.image
                  ? `${WEBSITE_BASE_URL}/public/subcategory/${row.image}`
                  : NO_IMAGE
              }
              alt="categoryimg"
            />
          </div>
        ),
      },
      {
        Header: "Created At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        headerStyle: {
          width: "200px",
        },
      },
      {
        Header: "Updated At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        headerStyle: {
          width: "200px",
        },
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <UIButton
              label={<i className="fa-regular fa-pen"></i>}
              type="secondary"
              onClick={() => handleEdit(row)}
            />

            <UIButton
              label={<i className="fa-regular fa-trash"></i>}
              type="error"
              onClick={() => handleDelete(row)}
            />
          </div>
        ),
        headerStyle: {
          width: "200px",
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => {
        if (!subCategory?.data) return [];
        return [...subCategory.data].sort(
          (a, b) => new Date(b.updated) - new Date(a.updated)
        );
      }, [subCategory?.data]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );
  const handleDelete = (item) => setShowDeleteModal(item);
  const resetDelete = () => setShowDeleteModal(null);
  const handleEdit = (item) => {
    setShowEditModal(item);
    setState({
      name: item.name,
      image: item.image,
    });
  };

  const resetEdit = () => setShowEditModal(null);

  const handleAddSubmit = () => {
    try {
      const { name } = state;
      if (!name) {
        const errors = {};
        if (!name) errors.name = "Name is required";
        setError(errors);
        errorToast("Please verify indicated fields");
        return;
      }

      const formData = new FormData();
      formData.append("name", state.name);
      formData.append("categoryId", id);
      if (image) {
        formData.append("image", image);
      }
      auth.token &&
        dispatch(
          insertSubCategory({
            data: formData,
            token: auth.token,
            callback: successInsert,
          })
        );
    } catch (error) {
      errorToast("Something went wrong with validation");
    }
  };

  const successInsert = () => {
    setState({
      name: "",
    });
    setImage(null);
    setShowInsertModal(false);
    successToast("Sub Category Inserted");
  };
  const handleEditSubmit = () => {
    try {
      const { name } = state;
      if (!name) {
        const errors = {};
        if (!name) errors.name = "Name is required";
        setError(errors);
        errorToast("Please verify indicated fields");
        return;
      }

      const formData = new FormData();
      formData.append("name", state.name);
      if (image) {
        formData.append("image", image);
      }
      showEditModal &&
        auth.token &&
        dispatch(
          editSubCategory({
            id: showEditModal?._id,
            data: formData,
            token: auth.token,
            callback: successEdit,
          })
        );
    } catch (error) {
      errorToast("Something went wrong with validation");
    }
  };

  const successEdit = () => {
    setState({
      name: "",
    });
    setImage(null);
    setShowEditModal(false);
    successToast("Sub Category Edited");
  };

  const deleteData = () =>
    showDeleteModal &&
    auth.token &&
    dispatch(
      deleteSubCategory({
        id: showDeleteModal._id,
        token: auth.token,
        callback: () => {
          setShowDeleteModal(null);
          successToast("Sub Category Deleted");
        },
      })
    );
  return (
    <Auth>
      {subCategory.loading && <Loader />}
      <div className="framecontainer">
        <Sidebar />
        <div className="framecontainer-content">
          <div className="content-wrapper">
            <Navbar name="Sub Categories" showBars />
            <div className="listpage">
              <div className="listpage-textlink">
                <div className="listpage-textlink--text">
                  <h1>Sub Category </h1>
                  <h2>
                    <span>
                      <i className="fa-regular fa-list-tree"></i>
                    </span>
                    &nbsp;/ Sub Category Management
                  </h2>
                </div>
                <div className="listpage-textlink--addsetting">
                  <UIButton
                    label={
                      <span>
                        <i className="fa-regular fa-plus"></i> Create
                      </span>
                    }
                    type="primary duotone"
                    onClick={() => setShowInsertModal(true)}
                  />
                </div>
              </div>

              <div className="listpage-searchentry">
                <div className="listpage-searchentry--search">
                  <UIInput
                    type="search"
                    placeholder="Search here..."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setGlobalFilter(e.target.value);
                    }}
                  />
                  <i className="fa-regular fa-magnifying-glass"></i>
                </div>
                <UISelect
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  options={[
                    { value: "5" },
                    { value: "10" },
                    { value: "25" },
                    { value: "50" },
                    { value: subCategory?.data?.length, displayValue: "All" },
                  ]}
                  style={{ width: "7rem" }}
                />
              </div>

              <div className="listpage-tablesection">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            style={column.headerStyle}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length} className="notfound">
                          Data not found.
                        </td>
                      </tr>
                    ) : (
                      page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell, cellIndex) => (
                              <td {...cell.getCellProps()}>
                                {cell.column.Header === "S.N"
                                  ? rowIndex + 1 + pageIndex * pageSize
                                  : cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {pageSize !== subCategory?.data?.length && (
                  <div className="listpage-tablesection--pagination">
                    <button
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>
                    <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </button>
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                      {">"}
                    </button>
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInsertModal && (
        <UIModal
          onClose={() => setShowInsertModal((prev) => !prev)}
          showAnimation
        >
          <div className="modalcontent-insert" style={{ width: "500px" }}>
            <h2 className="modalcontent-insert--heading">Add Sub Category</h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              >
                <UIInput
                  id="name"
                  label="Name"
                  placeholder="eg. Grill Chicken"
                  name="name"
                  type="text"
                  isRequired
                  onChange={handleInputField}
                  value={state.name}
                  error={error.name}
                />
                <UIFileInput
                  id="image"
                  label="Image"
                  style={{ width: "100%", height: "25rem" }}
                  onChange={handleInputField}
                />
              </div>
            </div>

            <div className="modalcontent-insert--actions">
              <UIButton
                label="Save"
                type="primary"
                onClick={handleAddSubmit}
                style={{ minWidth: "13rem" }}
              />
            </div>
          </div>
        </UIModal>
      )}

      {showEditModal && (
        <UIModal onClose={resetEdit} showAnimation>
          <div className="modalcontent-insert" style={{ width: "500px" }}>
            <h2 className="modalcontent-insert--heading">Edit Sub Category</h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              >
                <UIInput
                  id="name"
                  label="Name"
                  placeholder="eg. Grill Chicken"
                  name="name"
                  type="text"
                  isRequired
                  onChange={handleInputField}
                  value={state.name}
                  error={error.name}
                />
                <div style={{ display: "flex", gap: "1rem" }}>
                  <UIFileInput
                    id="image"
                    label="Image"
                    style={{ width: "100%", height: "25rem" }}
                    onChange={handleInputField}
                  />
                  {!image && (
                    <img
                      src={
                        state?.image
                          ? `${WEBSITE_BASE_URL}/public/subcategory/${state?.image} `
                          : NO_IMAGE
                      }
                      alt={state.name ?? ""}
                      quality={2}
                      width={1000}
                      height={1000}
                      style={{
                        width: "50%",
                        height: "25rem",
                        objectFit: "contain",
                        flex: "1",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="modalcontent-insert--actions">
              <UIButton
                label="Save"
                type="primary"
                onClick={handleEditSubmit}
                style={{ minWidth: "13rem" }}
              />
            </div>
          </div>
        </UIModal>
      )}

      {showDeleteModal ? (
        <UIDialogBox
          cancel={resetDelete}
          confirm={() => deleteData()}
          message="Are you sure you want to delete this item?"
          title="Confirm Delete"
        />
      ) : null}
    </Auth>
  );
};
