import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";

import UISelect from "../../ui/uiselect/UISelect";
import { UIInput } from "../../ui/uiinput/UIInput";

import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetail,
  getOrders,
  updateDeliveryStatus,
  updatePaymentStatus,
} from "../../redux/thunks/ordersThunk";
import Loader from "../../components/loader/Loader";
import UIButton from "../../ui/uibutton/UIButton";
import { resetOrderData } from "../../redux/slice/ordersSlice";
import UIModal from "../../ui/uimodal/UIModal";
import { errorToast, successToast } from "../../utils/toastify";
import { WEBSITE_BASE_URL } from "../../utils/config";
import { NO_IMAGE } from "../../constants/images";
import Auth from "../../components/Auth";
import UITextEditor from "../../ui/uitexteditor/UITextEditor";
import { parseInputType } from "../../utils/helper";

export const ListOrders = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(null);
  const orders = useSelector((state) => state.ordersState);
  const auth = useSelector((state) => state.authState);
  useEffect(() => {
    auth.token &&
      dispatch(
        getOrders({
          token: auth.token,
        })
      );
  }, [dispatch, auth.token]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Order Id",
        accessor: (row) => row.orderId,
      },
      {
        Header: "Ordered By",
        accessor: (row) =>
          row.isPickUp === true
            ? row?.pickup?.fullName
            : row?.deliveryData?.fullName,
        headerStyle: {
          whiteSpace: "nowrap",
          width: "200px",
        },
      },
      {
        Header: "Ordered Location",
        accessor: (row) =>
          row.isPickUp === true
            ? "Self Pickup"
            : row?.deliveryData?.deliveryLocation,
        headerStyle: {
          whiteSpace: "nowrap",
          width: "200px",
        },
      },
      {
        Header: "Order Method",
        accessor: (row) => row.orderMethod,
        headerStyle: {
          whiteSpace: "nowrap",
        },
      },
      {
        Header: "Order Date",
        accessor: (row) => moment(row.pickUpDate).format("MMM Do YYYY"),
        headerStyle: {
          whiteSpace: "nowrap",
        },
      },
      {
        Header: "Order Time",
        accessor: (row) => row.pickUpTime,
        headerStyle: {
          whiteSpace: "nowrap",
        },
      },
      {
        Header: "Payment Status",
        accessor: (row) => (
          <UIButton
            label={`${row.status}`}
            // type="success"
            style={{ width: "11rem" }}
            onClick={() => handlePayment(row)}
          />
        ),
      },
      {
        Header: "Delivery Status",
        accessor: (row) => (
          <UIButton
            label={`${row.deliveryStatus ?? "N/A"}`}
            // type="success"
            style={{ width: "12rem", textTransform: "capitalize" }}
            onClick={() => handleDelivery(row)}
          />
        ),
      },
      {
        Header: "Ordered At",
        accessor: (row) => moment(row.inserted).format("MMM Do YYYY"),
        headerStyle: {
          whiteSpace: "nowrap",
        },
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <UIButton
              label="View Details"
              type="secondary"
              onClick={() => handleEdit(row)}
            />
          </div>
        ),
        headerStyle: {
          width: "270px",
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => {
        if (!orders?.data) return [];
        return [...orders.data].sort(
          (a, b) => new Date(b.updated) - new Date(a.updated)
        );
      }, [orders?.data]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );
  const [paymentData, setPaymentData] = useState({
    status: "",
    remarks: "",
  });
  const [paymentError, setPaymentError] = useState({
    status: "",
    remarks: "",
  });
  const handlePayment = (item) => {
    setShowPaymentModal(item);
    setPaymentData({
      status: item.status,
      remarks: item.remarks,
    });
  };
  const resetPayment = () => {
    setShowPaymentModal(null);
    setPaymentData({
      status: "",
      remarks: "",
    });
  };
  const handleEdit = (item) => {
    setShowEditModal(item);
    dispatch(getOrderDetail({ token: auth.token, id: item.orderId }));
  };

  const resetEdit = () => {
    setShowEditModal(null);
    dispatch(resetOrderData());
  };
  const handlePaymentInputField = (e) => {
    const name = e.target.name;
    const type = e.target.type;
    const required = e.target.required;
    const value = parseInputType(type, e);

    const valid = value !== null && value !== undefined && value !== "";

    setPaymentData((prev) => ({ ...prev, [name]: value ?? undefined }));

    required &&
      valid &&
      setPaymentError((prev) => ({
        ...prev,
        [name]: value ? "" : "Field is required",
      }));
  };

  const handlePaymentSubmit = () => {
    try {
      if (!paymentData?.status) {
        errorToast("Payment Status is Required");
        return;
      }
      if (!paymentData?.remarks) {
        errorToast("Remarks is Required");
        return;
      }
      const data = {
        status: paymentData?.status,
        remarks: paymentData?.remarks,
      };
      auth.token &&
        dispatch(
          updatePaymentStatus({
            data: data,
            id: showPaymentModal.orderId,
            token: auth.token,
            callback: successPayment,
          })
        );
    } catch (error) {
      errorToast("Something went wrong with validation");
    }
  };

  const successPayment = () => {
    setPaymentData({
      status: "",
      remarks: "",
    });
    setShowPaymentModal(false);
    successToast("Payment Status Updated");
    auth.token &&
      dispatch(
        getOrders({
          token: auth.token,
        })
      );
  };

  const [totalAmount, setTotalAmount] = useState(0);

  const calculateTotalAmount = (items) => {
    let total = 0;
    items.forEach((item) => {
      total += parseInt(item?.price);
    });
    return total;
  };
  useEffect(() => {
    setTotalAmount(calculateTotalAmount(orders?.orderData));
  }, [orders?.orderData]);

  const [deliveryData, setDeliveryData] = useState({
    status: "",
  });
  const [deliveryError, setDeliveryError] = useState({
    status: "",
  });
  const handleDelivery = (item) => {
    setShowDeliveryModal(item);
    setDeliveryData({
      status: item.deliveryStatus,
    });
  };
  const resetDelivery = () => {
    setShowDeliveryModal(null);
    setDeliveryData({
      status: "",
    });
  };
  const handleDeliveryInputField = (e) => {
    const name = e.target.name;
    const type = e.target.type;
    const required = e.target.required;
    const value = parseInputType(type, e);

    const valid = value !== null && value !== undefined && value !== "";

    setDeliveryData((prev) => ({ ...prev, [name]: value ?? undefined }));

    required &&
      valid &&
      setDeliveryError((prev) => ({
        ...prev,
        [name]: value ? "" : "Field is required",
      }));
  };

  const handleDeliverySubmit = () => {
    try {
      if (!deliveryData?.status) {
        errorToast("Delivery Status is Required");
        return;
      }

      const data = {
        status: deliveryData?.status,
      };
      auth.token &&
        dispatch(
          updateDeliveryStatus({
            data: data,
            id: showDeliveryModal.orderId,
            token: auth.token,
            callback: successDelivery,
          })
        );
    } catch (error) {
      errorToast("Something went wrong with validation");
    }
  };

  const successDelivery = () => {
    setDeliveryData({
      status: "",
    });
    setShowDeliveryModal(false);
    successToast("Delivery Status Updated");
    auth.token &&
      dispatch(
        getOrders({
          token: auth.token,
        })
      );
  };
  return (
    <Auth>
      {orders.loading && <Loader />}
      <div className="framecontainer">
        <Sidebar />
        <div className="framecontainer-content">
          <div className="content-wrapper">
            <Navbar name="Orders" showBars />
            <div className="listpage">
              <div className="listpage-textlink">
                <div className="listpage-textlink--text">
                  <h1>Orders List</h1>
                  <h2>
                    <span>
                      <i className="fa-regular fa-cart-plus"></i>
                    </span>
                    &nbsp;/ Orders Management
                  </h2>
                </div>
                <div className="listpage-textlink--addsetting"></div>
              </div>

              <div className="listpage-searchentry">
                <div className="listpage-searchentry--search">
                  <UIInput
                    type="search"
                    placeholder="Search here..."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setGlobalFilter(e.target.value);
                    }}
                  />
                  <i className="fa-regular fa-magnifying-glass"></i>
                </div>
                <UISelect
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  options={[
                    { value: "5" },
                    { value: "10" },
                    { value: "25" },
                    { value: "50" },
                    { value: orders?.data?.length, displayValue: "All" },
                  ]}
                  style={{ width: "7rem" }}
                />
              </div>

              <div className="listpage-tablesection">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            style={column.headerStyle}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length} className="notfound">
                          Data not found.
                        </td>
                      </tr>
                    ) : (
                      page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell, cellIndex) => (
                              <td {...cell.getCellProps()}>
                                {cell.column.Header === "S.N"
                                  ? rowIndex + 1 + pageIndex * pageSize
                                  : cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {pageSize !== orders?.data?.length && (
                  <div className="listpage-tablesection--pagination">
                    <button
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>
                    <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </button>
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                      {">"}
                    </button>
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showEditModal && (
        <UIModal onClose={resetEdit} showAnimation>
          <div className="modalcontent-insert" style={{ width: "500px" }}>
            <h2 className="modalcontent-insert--heading">Order Details</h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              >
                <div className="clientdetails">
                  <div className="clientdetails-image">
                    <img
                      src={
                        orders?.orderData[0]?.customer?.image
                          ? `${WEBSITE_BASE_URL}/public/customer/${orders.orderData[0].customer?.image}`
                          : NO_IMAGE
                      }
                      alt=""
                    />
                  </div>
                  <div className="clientdetails-details">
                    <div className="clientdetails-details--name">
                      <span>Id : </span>
                      {orders?.orderData[0]?.customer._id}
                    </div>
                    <div className="clientdetails-details--name">
                      <span>Name : </span>
                      {orders?.orderData[0]?.customer.fullName}
                    </div>
                    <div
                      className="clientdetails-details--name"
                      style={{ textTransform: "lowercase" }}
                    >
                      <span style={{ textTransform: "capitalize" }}>
                        Email :{" "}
                      </span>
                      {orders?.orderData[0]?.customer.email}
                    </div>
                  </div>
                </div>

                <div
                  className="clientdetails-details--name"
                  style={{ marginTop: "1rem" }}
                >
                  <span>Order Id : </span>
                  {orders?.orderData[0]?.orderId}
                </div>
                <div className="clientdetails-details--name">
                  <span>Order date : </span>
                  {moment(orders?.orderData[0]?.date).format("MMM Do YYYY")}
                </div>

                <div className="clientdetails-details--name">
                  <span>Order time : </span>
                  {orders?.orderData[0]?.time}
                </div>
                <div className="clientdetails-details--name">
                  <span>Payment Method : </span>
                  {orders?.orderData[0]?.paymentMethod}
                </div>
                <div className="clientdetails-details--name">
                  <span>Payment Status : </span>
                  {orders?.orderData[0]?.paymentStatus}
                </div>
                <div className="clientdetails-details--name">
                  <span>Delivery Status : </span>
                  {orders?.orderData[0]?.deliveryStatus ?? "N/A"}
                </div>
                <div className="clientdetails-details--name">
                  <span>Remarks : </span>
                  {orders?.orderData[0]?.remarks ?? "N/A"}
                </div>
                {orders?.orderData[0]?.delivery !== null && (
                  <>
                    <div className="clientdetails-head">
                      Delivery Address Details
                    </div>
                    <div className="clientdetails-details--name">
                      <span>
                        <i className="fa-regular fa-user"></i> Full Name :{" "}
                      </span>
                      {orders?.orderData[0]?.delivery?.fullName}
                    </div>
                    <div className="clientdetails-details--name">
                      <span>
                        <i className="fa-regular fa-phone"></i> phone :{" "}
                      </span>
                      {orders?.orderData[0]?.delivery?.number}
                    </div>
                    <div className="clientdetails-details--name">
                      <span>
                        <i className="fa-regular fa-truck"></i> Delivery To :{" "}
                      </span>
                      {orders?.orderData[0]?.delivery?.addressTitle}
                    </div>
                    <div className="clientdetails-details--name">
                      <span>
                        <i className="fa-regular fa-location-dot"></i> Delivery
                        Location :{" "}
                      </span>
                      {orders?.orderData[0]?.delivery?.deliveryLocation}
                    </div>
                    <div className="clientdetails-details--name">
                      <span>
                        <i className="fa-regular fa-location-crosshairs"></i>{" "}
                        Delivery Description :{" "}
                      </span>
                      {orders?.orderData[0]?.delivery?.description}
                    </div>
                  </>
                )}
                <div className="clientdetails-head">Order Items</div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Food Name</th>
                        <th>Quantity</th>
                        <th>price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.orderData?.map((value, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{value.food.name}</td>
                          <td>{value.quantity}</td>
                          <td>{value.price / value.quantity}</td>
                          <td>{value.price + " Kr"}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={4}>Total</td>
                        <td>{totalAmount + " Kr"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </UIModal>
      )}

      {showPaymentModal && (
        <UIModal onClose={resetPayment} showAnimation>
          <div className="modalcontent-insert" style={{ width: "500px" }}>
            <h2 className="modalcontent-insert--heading">
              Update Payment Status
            </h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%", minHeight: "10rem" }}
              >
                <UISelect
                  onChange={handlePaymentInputField}
                  placeholder="Select Payment Status"
                  label="Payment Status"
                  name="status"
                  id="status"
                  isRequired
                  defaultValue={paymentData.status}
                  options={[
                    { value: "Paid", displayValue: "Paid" },
                    { value: "Pending", displayValue: "Pending" },
                    { value: "Failed", displayValue: "Failed" },
                  ]}
                  error={paymentError.status}
                />
                <UITextEditor
                  id="remarks"
                  label="Remarks"
                  name="remarks"
                  isRequired
                  onChange={handlePaymentInputField}
                  defaultValue={paymentData.remarks}
                  error={paymentError.remarks}
                />
              </div>
            </div>
            <div className="modalcontent-insert--actions">
              <UIButton
                label="Save"
                type="primary"
                onClick={handlePaymentSubmit}
                style={{ minWidth: "13rem" }}
              />
            </div>
          </div>
        </UIModal>
      )}

      {showDeliveryModal && (
        <UIModal onClose={resetDelivery} showAnimation>
          <div className="modalcontent-insert" style={{ width: "500px" }}>
            <h2 className="modalcontent-insert--heading">
              Update Delivery Status
            </h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%", minHeight: "15rem" }}
              >
                <UISelect
                  onChange={handleDeliveryInputField}
                  placeholder="Select Delivery Status"
                  label="Delivery Status"
                  name="status"
                  id="status"
                  isRequired
                  defaultValue={deliveryData.status}
                  options={[
                    { value: "placed", displayValue: "Placed" },
                    { value: "confirmed", displayValue: "Confirmed" },
                    { value: "processed", displayValue: "Processed" },
                    { value: "ready", displayValue: "Ready" },
                    { value: "delivered", displayValue: "Delivered" },
                  ]}
                  error={deliveryError.status}
                />
              </div>
            </div>
            <div className="modalcontent-insert--actions">
              <UIButton
                label="Save"
                type="primary"
                onClick={handleDeliverySubmit}
                style={{ minWidth: "13rem" }}
              />
            </div>
          </div>
        </UIModal>
      )}
    </Auth>
  );
};
