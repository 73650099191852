import { createSlice } from "@reduxjs/toolkit";

import { API_EXPIRE_TIME } from "../../constants/constants";
import {
  deleteGallery,
  getGallery,
  insertGallery,
} from "../thunks/galleryThunk";

const initialState = {
  data: [],
  loading: false,
  expires: null,
  error: null,
  status: "All",
  disableConfig: false,
};

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //!Fetch
      .addCase(getGallery.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getGallery.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getGallery.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch gallerys data";
        state.expires = null;
      })
      // .addCase(insertGallery.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(insertGallery.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.data = [action.payload, ...state.data];
      //   state.error = null;
      // })
      // .addCase(insertGallery.rejected, (state) => {
      //   state.loading = false;
      //   state.error = "Failed to insert gallery data";
      // })
      .addCase(deleteGallery.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGallery.fulfilled, (state, action) => {
        state.loading = false;

        state.data = state.data.filter((i) => i._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteGallery.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to delete gallery data";
      });
  },
});

// export const { setStatus } = gallerySlice.actions;
export default gallerySlice.reducer;
