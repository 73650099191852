import React, { useEffect, useState } from "react";
import { BG, LOGO } from "../../constants/images";
import { UIInput } from "../../ui/uiinput/UIInput";
import UIButton from "../../ui/uibutton/UIButton";
import { dismissToast, errorToast } from "../../utils/toastify";
import { login } from "../../redux/thunks/authThunk";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../redux/slice/authSlice";
import Loader from "../../components/loader/Loader";

export const Login = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState({
    username: "",
    password: "",
  });

  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.authState);

  const handleLogin = (e) => {
    e.preventDefault();
    try {
      if (!state.username) {
        errorToast("Username is Required");
        return;
      }
      if (!state.password) {
        errorToast("Password is Required");
        return;
      }
      dispatch(login(state));
    } catch (error) {
      errorToast("Something went wrong with validaton");
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    setState((prev) => ({ ...prev, [e.target.name]: value ?? "" }));
    value &&
      setError((prev) => ({
        ...prev,
        [e.target.name]: "",
      }));
  };

  useEffect(() => {
    dismissToast();
    dispatch(setLoading(false));
    if (authSelector.isLoggedIn && authSelector.token) {
      navigate("/dashboard");
    }
  }, [authSelector.isLoggedIn, authSelector.token, navigate, dispatch]);
  return (
    <>
      {authSelector.loading && <Loader />}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${BG})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <div className="loginform">
          <div className="loginform-loginformtop">
            <div className="loginform-loginformtop--loginformtoptext">
              <h1>Welcome Back</h1>
              <h2>
                Sign in to <strong>Curry Heaven</strong> Dashboard
              </h2>
            </div>
            <div className="loginform-loginformtop--loginformtopimage">
              <div className="loginform-loginformtop--loginformtopimage_image">
                <img src={LOGO} alt="" height="100%" width="100%" />
              </div>
            </div>
          </div>

          <form className="loginform-loginformform">
            <div className="loginform-loginformform--flex">
              <UIInput
                id="username"
                name="username"
                isRequired
                label="Username"
                placeholder="eg. curry-heaven"
                type="username"
                error={error.username}
                onChange={handleInput}
                value={state.username}
              />

              <UIInput
                id="password"
                name="password"
                isRequired
                label="Password"
                placeholder="eg. ********"
                type="password"
                error={error.password}
                onChange={handleInput}
                value={state.password}
              />
            </div>
            <div className="loginform-loginformform--submit">
              <UIButton
                label="Login"
                onClick={handleLogin}
                type="primary duotone"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
