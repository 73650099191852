import { createAsyncThunk } from "@reduxjs/toolkit";
import { doPatch } from "../../utils/axios";

export const changePassword = createAsyncThunk(
  "changePassword",
  async ({ data, token, id, callback }) => {
    try {
      const response = await doPatch(`admin/change-password/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);
