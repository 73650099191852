import React, { useEffect, useRef, useState } from "react";

export default function UISelect({
  id,
  label,
  name,
  isRequired,
  placeholder,
  style,
  error,
  prefix,
  onChange,
  options,
  defaultValue,
  disabled,
  showSearch,
  instruction,
}) {
  const wrapperRef = useRef(null);

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [keyword, setKeyword] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const toggleShow = (val) => setShow(val);
  const handleSearch = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword ? keyword.trim() : null);
  };
  const handleOptionSelect = (e) => {
    setSelectedOption(e);
    setSelectedIndex(-1);
    onChange &&
      onChange({
        target: {
          name: name ?? "SelectField",
          value: e.value,
          required: isRequired ?? false,
          type: "text",
        },
      });

    toggleShow(false);
  };
  const handleDisplayEvent = () => {
    show ? toggleShow(false) : !disabled && toggleShow(true);
  };

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (
        wrapperRef.current &&
        typeof wrapperRef.current === "object" &&
        !wrapperRef.current?.contains(e.target)
      ) {
        toggleShow(false);
        setFilteredOptions(options);
        setSelectedIndex(-1);
      }
    });
    return () => {
      document.removeEventListener("mousedown", () => null);
    };
  }, [wrapperRef, options]);

  useEffect(() => {
    let searchTimeout;
    if (keyword) {
      searchTimeout = setTimeout(() => {
        setFilteredOptions(
          options.filter(
            (i) => (i.search ?? i.value).toLowerCase().indexOf(keyword) > -1
          )
        );
      }, 500);
    } else {
      setFilteredOptions(options);
    }

    return () => {
      clearTimeout(searchTimeout ? searchTimeout : undefined);
    };
  }, [keyword, options]);

  return (
    <div className="selectfield" style={style} ref={wrapperRef}>
      {label ? (
        <label
          htmlFor={id ? id : ""}
          className="selectfield-label"
          onClick={handleDisplayEvent}
        >
          {label} {isRequired ? "*" : ""}
        </label>
      ) : null}

      <div className="selectfield-wrapper">
        {!show || !showSearch ? (
          <div
            className={`selectfield-display ${error ? "error" : ""}`}
            onClick={handleDisplayEvent}
            tabIndex={0}
            onKeyDown={(e) => (e.key === "Enter" ? handleDisplayEvent() : null)}
          >
            <div className="selectfield-display--wrapper">
              {prefix ? (
                <div className="selectfield-display--prefix">{prefix}</div>
              ) : (
                ""
              )}
              <div className="selectfield-display--value">
                {selectedOption
                  ? selectedOption.displayValue ?? selectedOption.value
                  : defaultValue && options.find((i) => i.value == defaultValue)
                  ? options.find((i) => i.value == defaultValue)
                      ?.displayValue ??
                    options.find((i) => i.value == defaultValue)?.value
                  : placeholder ??
                    (options[0]
                      ? options[0].displayValue ?? options[0].value
                      : "Select")}
              </div>
            </div>
            <i className="fa-regular fa-chevron-down selectfield-display--arrow"></i>
          </div>
        ) : (
          <div className={`selectfield-search ${error ? "error" : ""}`}>
            <div className="selectfield-search--wrapper">
              <div className="selectfield-search--prefix">
                <i className="fa-regular fa-search"></i>
              </div>
              <input
                type="text"
                autoFocus={true}
                placeholder="Search.."
                className="selectfield-search--input"
                onChange={handleSearch}
              />
            </div>
            <i
              className="fa-regular fa-times selectfield-search--arrow"
              onClick={() => toggleShow(false)}
            ></i>
          </div>
        )}
      </div>

      {show ? (
        <div className="selectfield-options">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((child, index) => {
              return (
                <div
                  className={`selectfield-options--item ${
                    (selectedOption && selectedOption.value === child.value) ||
                    (!selectedOption && child.value === defaultValue)
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleOptionSelect(child)}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? handleOptionSelect(child) : null
                  }
                  key={child.value}
                  tabIndex={0}
                >
                  {child.displayValue ? child.displayValue : child.value}
                </div>
              );
            })
          ) : (
            <div className="selectfield-blank">No Options Found</div>
          )}
        </div>
      ) : null}

      {error ? <span className="selectfield-error">{error}</span> : null}

      {instruction ? (
        <p className="inputfield-instruction">{instruction}</p>
      ) : null}
    </div>
  );
}
