import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPatch, doPost } from "../../utils/axios";

export const getSubCategory = createAsyncThunk(
  "getSubCategory",
  async ({ token, id }) => {
    try {
      const response = await doGet(`/subcategories/category/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getTotalSubCategory = createAsyncThunk(
  "getTotalSubCategory",
  async ({ token, id }) => {
    try {
      const response = await doGet(`/subcategories`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const insertSubCategory = createAsyncThunk(
  "insertSubCategory",
  async ({ data, token, callback }) => {
    try {
      const response = await doPost(`/subcategories`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const editSubCategory = createAsyncThunk(
  "editSubCategory",
  async ({ data, token, id, callback }) => {
    try {
      const response = await doPatch(`/subcategories/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteSubCategory = createAsyncThunk(
  "deleteSubCategory",
  async ({ id, token, callback }) => {
    try {
      await doDelete(`/subcategories/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return id;
    } catch (error) {
      throw error;
    }
  }
);
