import { Link } from "react-router-dom";

export default function UIButton({
  to,
  id,
  label,
  onClick,
  style,
  type,
  className,
  target,
}) {
  return to ? (
    <Link
      id={id}
      to={to}
      className={`btn btn-${type ? type : "link"}`}
      style={style}
      target={target}
    >
      {label}
    </Link>
  ) : (
    <button
      id={id}
      className={`btn btn-${type ? type : "plain"} ${
        className ? className : ""
      }`}
      style={style}
      onClick={onClick}
    >
      {label}
    </button>
  );
}
