import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { LOGO } from "../../constants/images";

const menuItems = [
  { href: "/dashboard", title: "Home", iconName: "fa-home" },
  { href: "/orders", title: "Orders", iconName: "fa-cart-plus" },
  { href: "/banner", title: "Banner", iconName: "fa-flag-pennant" },
  { href: "/category", title: "Category", iconName: "fa-list-tree" },
  { href: "/menu", title: "Menu", iconName: "fa-memo-circle-check" },
  { href: "/gallery", title: "Gallery", iconName: "fa-images" },
  { href: "/user", title: "User", iconName: "fa-users" },
  { href: "/table-booking", title: "Table Booking", iconName: "fa-chair" },

  { href: "/faq", title: "FAQ", iconName: "fa-person-circle-question" },
  { href: "/query", title: "Query", iconName: "fa-circle-question" },
  { href: "/blog", title: "Blog", iconName: "fa-blog" },
  {
    href: "/testimonial",
    title: "Testimonial",
    iconName: "fa-head-side-heart",
  },
];

export default function Sidebar() {
  const location = useLocation();
  const systemSelector = useSelector((state) => state.systemState);

  return (
    <div
      className="sidebar"
      style={{ width: systemSelector.menu ? "18%" : "10%" }}
    >
      <div className="sidebar-top">
        <div className="sidebar-top--logo">
          <img
            src={LOGO}
            alt="Esquare logo"
            className="sidebar-top--logo__icon"
          />
        </div>
        <ul className={`sidebar-top--menu`}>
          {menuItems.map((value, index) => {
            return (
              <MenuItem
                href={value.href}
                title={value.title}
                isActive={location.pathname.indexOf(value.href) > -1}
                iconName={value.iconName}
                key={index}
                showLabel={systemSelector.menu}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
}

const MenuItem = ({ href, iconName, title, isActive, showLabel }) => {
  return (
    <li
      className={`sidebar-top--menu__item${isActive ? " active" : ""} ${
        showLabel ? "open" : "close"
      }`}
    >
      <Link to={href} title={title}>
        <i className={`fa-solid ${iconName}`}></i>
        {showLabel ? <span>{title}</span> : null}
      </Link>
    </li>
  );
};
