import { createAsyncThunk } from "@reduxjs/toolkit";
import { successToast } from "../../utils/toastify";
import { doPost } from "../../utils/axios";

export const login = createAsyncThunk("login", async (data) => {
  try {
    const response = await doPost("/admins/login", data);
    successToast("Login success");
    return response;
  } catch (error) {
    throw error;
  }
});
