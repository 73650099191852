import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import UIButton from "../../ui/uibutton/UIButton";
import UISelect from "../../ui/uiselect/UISelect";
import UIDialogBox from "../../ui/uidialogbox/UIDialogBox";
import { UIInput } from "../../ui/uiinput/UIInput";
import { NO_IMAGE } from "../../constants/images";
import UIModal from "../../ui/uimodal/UIModal";
import UIFileInput from "../../ui/uifileinput/UIFileInput";
import { parseInputType } from "../../utils/helper";
import { errorToast, successToast } from "../../utils/toastify";
import { useDispatch, useSelector } from "react-redux";
import { blockUser, getUser, unblockUser } from "../../redux/thunks/userThunk";
import { WEBSITE_BASE_URL } from "../../utils/config";
import UITextEditor from "../../ui/uitexteditor/UITextEditor";
import Loader from "../../components/loader/Loader";
import Auth from "../../components/Auth";

export const ListUser = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showUnblockModal, setShowUnblockModal] = useState(false);
  const [state, setState] = useState({
    blockedMessage: "",
  });
  const [error, setError] = useState({
    blockedMessage: "",
  });

  const [image, setImage] = useState("");
  const handleInputField = (e) => {
    const name = e.target.name;
    const type = e.target.type;
    const required = e.target.required;
    const value = parseInputType(type, e);

    const valid = value !== null && value !== undefined && value !== "";
    if (type === "file") {
      const file = e.target.files[0];
      setImage(file);
      return;
    }

    setState((prev) => ({ ...prev, [name]: value ?? undefined }));

    required &&
      valid &&
      setError((prev) => ({
        ...prev,
        [name]: value ? "" : "Field is required",
      }));
  };

  const user = useSelector((state) => state.userState);
  const auth = useSelector((state) => state.authState);
  useEffect(() => {
    auth.token &&
      dispatch(
        getUser({
          token: auth.token,
        })
      );
  }, [dispatch, auth.token]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Full Name",
        accessor: (row) => row.fullName,
        Cell: ({ value }) => (
          <div style={{ textTransform: "capitalize" }}>{value}</div>
        ),
      },
      {
        Header: "Email",
        accessor: (row) => row.email,
      },
      {
        Header: "Phone",
        accessor: (row) => row.phoneNumber ?? "N/A",
      },
      {
        Header: "Image",
        accessor: (row) => (
          <div className="tableimage">
            <img
              src={
                row.image
                  ? `${WEBSITE_BASE_URL}/public/user/${row.image}`
                  : NO_IMAGE
              }
              alt="userimg"
            />
          </div>
        ),
      },
      {
        Header: "Address",
        accessor: (row) => row.address ?? "N/A",
      },
      // {
      //   Header: "Verified",
      //   accessor: (row) => (
      //     <UIButton
      //       label={row.verified ? "Verified" : "Not Verfied"}
      //       type={row.verified ? "success" : "error"}
      //     />
      //   ),
      // },
      {
        Header: "isBlocked",
        accessor: (row) => (
          <UIButton
            label={row.isBlocked ? "Unblock" : "Block"}
            type={row.isBlocked ? "success" : "error"}
            onClick={() => {
              row.isBlocked ? handleUnblock(row) : handleBlock(row);
            }}
          />
        ),
      },
      {
        Header: "Created At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        headerStyle: {
          width: "150px",
        },
      },

      // {
      //   Header: "Action",
      //   accessor: (row) => (
      //     <div className="actionlinks">
      //       <UIButton
      //         label={<i className="fa-regular fa-trash"></i>}
      //         type="error"
      //         onClick={() => handleUnblock(row)}
      //       />
      //     </div>
      //   ),
      //   headerStyle: {
      //     width: "100px",
      //   },
      // },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => {
        if (!user?.data) return [];
        return [...user.data].sort(
          (a, b) => new Date(b.updated) - new Date(a.updated)
        );
      }, [user?.data]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );
  const handleUnblock = (item) => setShowUnblockModal(item);
  const resetUnblock = () => setShowUnblockModal(null);
  const handleBlock = (item) => {
    setShowBlockModal(item);
    setState({
      name: item.name,
      image: item.image,
    });
  };

  const resetBlock = () => {
    setShowBlockModal(null);
    setState({ blockedMessage: "" });
  };

  const handleBlockSubmit = () => {
    try {
      const { blockedMessage } = state;
      if (!blockedMessage) {
        const errors = {};
        if (!blockedMessage)
          errors.blockedMessage = "Block message is required";
        setError(errors);
        errorToast("Please verify indicated fields");
        return;
      }
      const data = {
        _id: showBlockModal?._id,
        blockedMessage: state.blockedMessage,
      };

      showBlockModal &&
        auth.token &&
        dispatch(
          blockUser({
            data: data,
            token: auth.token,
            callback: successBlock,
          })
        );
    } catch (error) {
      errorToast("Something went wrong with validation");
    }
  };

  const successBlock = () => {
    setState({
      blockedMessage: "",
    });
    setImage(null);
    setShowBlockModal(false);
    dispatch(
      getUser({
        token: auth.token,
      })
    );
    successToast("User Blocked");
  };

  const unblockData = () => {
    const data = { _id: showUnblockModal._id };
    if (showUnblockModal && auth.token) {
      dispatch(
        unblockUser({
          data: data,
          token: auth.token,
          callback: () => {
            setShowUnblockModal(null);
            dispatch(
              getUser({
                token: auth.token,
              })
            );
            successToast("User Unblocked");
          },
        })
      );
    }
  };

  return (
    <Auth>
      {user.loading && <Loader />}
      <div className="framecontainer">
        <Sidebar />
        <div className="framecontainer-content">
          <div className="content-wrapper">
            <Navbar name="Users" showBars />
            <div className="listpage">
              <div className="listpage-textlink">
                <div className="listpage-textlink--text">
                  <h1>User List</h1>
                  <h2>
                    <span>
                      <i className="fa-regular fa-users"></i>
                    </span>
                    &nbsp;/ User Management
                  </h2>
                </div>
                <div className="listpage-textlink--addsetting"></div>
              </div>

              <div className="listpage-searchentry">
                <div className="listpage-searchentry--search">
                  <UIInput
                    type="search"
                    placeholder="Search here..."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setGlobalFilter(e.target.value);
                    }}
                  />
                  <i className="fa-regular fa-magnifying-glass"></i>
                </div>
                <UISelect
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  options={[
                    { value: "5" },
                    { value: "10" },
                    { value: "25" },
                    { value: "50" },
                    { value: user?.data?.length, displayValue: "All" },
                  ]}
                  style={{ width: "7rem" }}
                />
              </div>

              <div className="listpage-tablesection">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            style={column.headerStyle}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length} className="notfound">
                          Data not found.
                        </td>
                      </tr>
                    ) : (
                      page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell, cellIndex) => (
                              <td {...cell.getCellProps()}>
                                {cell.column.Header === "S.N"
                                  ? rowIndex + 1 + pageIndex * pageSize
                                  : cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {pageSize !== user?.data?.length && (
                  <div className="listpage-tablesection--pagination">
                    <button
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>
                    <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </button>
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                      {">"}
                    </button>
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showBlockModal && (
        <UIModal onClose={resetBlock} showAnimation>
          <div className="modalcontent-insert" style={{ width: "500px" }}>
            <h2 className="modalcontent-insert--heading">Block User</h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              >
                <UITextEditor
                  id="blockedMessage"
                  label="Block Message"
                  rows={5}
                  name="blockedMessage"
                  isRequired
                  onChange={handleInputField}
                  defaultValue={state.blockedMessage}
                  error={error.blockedMessage}
                />
              </div>
            </div>

            <div className="modalcontent-insert--actions">
              <UIButton
                label="Save"
                type="primary"
                onClick={handleBlockSubmit}
                style={{ minWidth: "13rem" }}
              />
            </div>
          </div>
        </UIModal>
      )}

      {showUnblockModal ? (
        <UIDialogBox
          cancel={resetUnblock}
          confirm={() => unblockData()}
          message="Are you sure you want to unblock this user?"
          title="Confirm Unblock"
        />
      ) : null}
    </Auth>
  );
};
