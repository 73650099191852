import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPatch, doPost } from "../../utils/axios";

export const getMenu = createAsyncThunk("getMenu", async ({ token }) => {
  try {
    const response = await doGet(`/foods`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
});

export const insertMenu = createAsyncThunk(
  "insertMenu",
  async ({ data, token, callback }) => {
    try {
      const response = await doPost(`/foods`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const editMenu = createAsyncThunk(
  "editMenu",
  async ({ data, token, id, callback }) => {
    try {
      const response = await doPatch(`/foods/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteMenu = createAsyncThunk(
  "deleteMenu",
  async ({ id, token, callback }) => {
    try {
      await doDelete(`/foods/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return id;
    } catch (error) {
      throw error;
    }
  }
);
