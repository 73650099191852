import { createSlice } from "@reduxjs/toolkit";
import { login } from "../thunks/authThunk";

const initialState = {
  error: null,
  isLoggedIn: false,
  loading: false,
  token: null,
  id: null,
};

const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.error = null;
      state.loading = false;
      state.isLoggedIn = false;
      state.token = null;
      state.id = null;
    },
    rehydrateAuth: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.token = action.payload.accessToken;
      state.error = action.payload.error;
      state.loading = action.payload.loading;
      state.id = action.payload.id;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.loading = false;
        state.token = action.payload.token;
        state.error = null;
        state.id = action.payload.id;
      })
      .addCase(login.rejected, (state) => {
        state.error = "Something went wrong";
        state.loading = false;
        state.isLoggedIn = false;
        state.token = null;
      });
  },
});

export const { logout, rehydrateAuth, setLoading } = authSlice.actions;
export default authSlice.reducer;
