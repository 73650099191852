import { createSlice } from "@reduxjs/toolkit";

import { API_EXPIRE_TIME } from "../../constants/constants";
import { getQuery } from "../thunks/queryThunk";
const initialState = {
  data: [],
  loading: false,
  expires: null,
  error: null,
  status: "All",
  disableConfig: false,
};

const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //!Fetch
      .addCase(getQuery.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getQuery.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getQuery.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch querys data";
        state.expires = null;
      });
  },
});

// export const { setStatus } = querySlice.actions;
export default querySlice.reducer;
