import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
// import UIButton from "../../ui/uibutton/UIButton";
import UISelect from "../../ui/uiselect/UISelect";
// import UIDialogBox from "../../ui/uidialogbox/UIDialogBox";
import { UIInput } from "../../ui/uiinput/UIInput";
// import UIModal from "../../ui/uimodal/UIModal";
import { useDispatch, useSelector } from "react-redux";
import { getQuery } from "../../redux/thunks/queryThunk";
import Loader from "../../components/loader/Loader";
import Auth from "../../components/Auth";
// import { successToast } from "../../utils/toastify";

export const ListQuery = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [showEditModal, setShowEditModal] = useState(null);

  const query = useSelector((state) => state.queryState);
  const auth = useSelector((state) => state.authState);
  useEffect(() => {
    auth.token &&
      dispatch(
        getQuery({
          token: auth.token,
        })
      );
  }, [dispatch, auth.token]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Full Name",
        accessor: (row) => row.name,
        Cell: ({ value }) => (
          <div style={{ textTransform: "capitalize" }}>{value}</div>
        ),
      },
      {
        Header: "Email",
        accessor: (row) => row.email,
        Cell: ({ value }) => (
          <div style={{ textTransform: "capitalize" }}>{value}</div>
        ),
      },
      {
        Header: "Message",
        accessor: (row) => row.message,
      },
      {
        Header: "Contacted At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        headerStyle: {
          width: "200px",
        },
      },
      // {
      //   Header: "Action",
      //   accessor: (row) => (
      //     <div className="actionlinks">
      //       {/* <UIButton
      //         label={<i className="fa-regular fa-eye"></i>}
      //         type="secondary"
      //         onClick={() => handleEdit(row)}
      //       /> */}

      //       <UIButton
      //         label={<i className="fa-regular fa-trash"></i>}
      //         type="error"
      //         onClick={() => handleDelete(row)}
      //       />
      //     </div>
      //   ),
      //   headerStyle: {
      //     width: "200px",
      //   },
      // },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => {
        if (!query?.data) return [];
        return [...query.data].sort(
          (a, b) => new Date(b.updated) - new Date(a.updated)
        );
      }, [query?.data]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );
  // const handleDelete = (item) => setShowDeleteModal(item);
  // const resetDelete = () => setShowDeleteModal(null);
  // const handleEdit = (item) => {
  //   setShowEditModal(item);
  // };
  // console.log(showEditModal);
  // const resetEdit = () => setShowEditModal(null);

  // const deleteData = () =>
  //   showDeleteModal &&
  //   auth.token &&
  //   dispatch(
  //     deleteCategory({
  //       id: showDeleteModal._id,
  //       token: auth.token,
  //       callback: () => {
  //         setShowDeleteModal(null);
  //         successToast("Category Deleted");
  //       },
  //     })
  //   );
  return (
    <Auth>
      {query.loading && <Loader />}
      <div className="framecontainer">
        <Sidebar />
        <div className="framecontainer-content">
          <div className="content-wrapper">
            <Navbar name="Queries" showBars />
            <div className="listpage">
              <div className="listpage-textlink">
                <div className="listpage-textlink--text">
                  <h1>Query List</h1>
                  <h2>
                    <span>
                      <i className="fa-regular fa-circle-question"></i>
                    </span>
                    &nbsp;/ Query Management
                  </h2>
                </div>
                <div className="listpage-textlink--addsetting"></div>
              </div>

              <div className="listpage-searchentry">
                <div className="listpage-searchentry--search">
                  <UIInput
                    type="search"
                    placeholder="Search here..."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setGlobalFilter(e.target.value);
                    }}
                  />
                  <i className="fa-regular fa-magnifying-glass"></i>
                </div>
                <UISelect
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  options={[
                    { value: "5" },
                    { value: "10" },
                    { value: "25" },
                    { value: "50" },
                    { value: query?.data?.length, displayValue: "All" },
                  ]}
                  style={{ width: "7rem" }}
                />
              </div>

              <div className="listpage-tablesection">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            style={column.headerStyle}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length} className="notfound">
                          Data not found.
                        </td>
                      </tr>
                    ) : (
                      page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell, cellIndex) => (
                              <td {...cell.getCellProps()}>
                                {cell.column.Header === "S.N"
                                  ? rowIndex + 1 + pageIndex * pageSize
                                  : cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {pageSize !== query?.data?.length && (
                  <div className="listpage-tablesection--pagination">
                    <button
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>
                    <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </button>
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                      {">"}
                    </button>
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {showEditModal && (
        <UIModal onClose={resetEdit} showAnimation>
          <div className="modalcontent-insert" style={{ width: "500px" }}>
            <h2 className="modalcontent-insert--heading">Edit Category</h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              ></div>
            </div>
          </div>
        </UIModal>
      )} */}

      {/* {showDeleteModal ? (
        <UIDialogBox
          cancel={resetDelete}
          confirm={() => deleteData()}
          message="Are you sure you want to delete this item?"
          title="Confirm Delete"
        />
      ) : null} */}
    </Auth>
  );
};
