import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./style/main.min.css";
import { Login } from "./pages/login/Login";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { ListCategory } from "./pages/category/ListCategory";
import { ListSubCategory } from "./pages/subcategory/ListSubCategory";
import { ListBanner } from "./pages/banner/ListBanner";
import { ListGallery } from "./pages/gallery/ListGallery";
import { ListQuery } from "./pages/query/ListQuery";
import { ListMenu } from "./pages/menu/ListMenu";
import { ListTableBooking } from "./pages/table-booking/ListTableBooking";
import { ListBlog } from "./pages/blog/ListBlog";
import { ListFAQ } from "./pages/faq/ListFAQ";
import { ListUser } from "./pages/user/ListUser";
import { ListOrders } from "./pages/orders/ListOrders";
import { ListTestimonial } from "./pages/testimonial/ListTestimonial";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/category" element={<ListCategory />} />
          <Route path="/banner" element={<ListBanner />} />
          <Route path="/gallery" element={<ListGallery />} />
          <Route path="/query" element={<ListQuery />} />
          <Route path="/menu" element={<ListMenu />} />
          <Route path="/blog" element={<ListBlog />} />
          <Route path="/faq" element={<ListFAQ />} />
          <Route path="/user" element={<ListUser />} />
          <Route path="/orders" element={<ListOrders />} />
          <Route path="/testimonial" element={<ListTestimonial />} />
          <Route path="/table-booking" element={<ListTableBooking />} />
          <Route path="/category/:id" element={<ListSubCategory />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
