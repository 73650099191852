import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPatch, doPost } from "../../utils/axios";

export const getFAQ = createAsyncThunk("getFAQ", async ({ token }) => {
  try {
    const response = await doGet(`/faqs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
});

export const insertFAQ = createAsyncThunk(
  "insertFAQ",
  async ({ data, token, callback }) => {
    try {
      const response = await doPost(`/faqs`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const editFAQ = createAsyncThunk(
  "editFAQ",
  async ({ data, token, id, callback }) => {
    try {
      const response = await doPatch(`/faqs/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteFAQ = createAsyncThunk(
  "deleteFAQ",
  async ({ id, token, callback }) => {
    try {
      await doDelete(`/faqs/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return id;
    } catch (error) {
      throw error;
    }
  }
);
