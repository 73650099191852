import { createSlice } from "@reduxjs/toolkit";

import { API_EXPIRE_TIME } from "../../constants/constants";
import {
  deleteSubCategory,
  editSubCategory,
  getSubCategory,
  getTotalSubCategory,
  insertSubCategory,
} from "../thunks/subCategoryThunk";

const initialState = {
  data: [],
  total: [],
  loading: false,
  expires: null,
  error: null,
  status: "All",
  disableConfig: false,
};

const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //!Fetch
      .addCase(getSubCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getSubCategory.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getSubCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch subCategorys data";
        state.expires = null;
      })
      .addCase(insertSubCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertSubCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [action.payload, ...state.data];
        state.error = null;
      })
      .addCase(insertSubCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to insert subCategory data";
      })
      .addCase(deleteSubCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSubCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter((i) => i._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteSubCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to delete subCategory data";
      })
      //!Edit
      .addCase(editSubCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editSubCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.map((i) =>
          i._id === action.payload._id
            ? {
                ...action.payload,
              }
            : i
        );
        state.error = null;
      })

      .addCase(editSubCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to edit subCategory data";
      })
      .addCase(getTotalSubCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getTotalSubCategory.fulfilled, (state, action) => {
        state.loading = false;

        state.total = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getTotalSubCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch subCategorys data";
        state.expires = null;
      });
  },
});

// export const { setStatus } = subCategorySlice.actions;
export default subCategorySlice.reducer;
