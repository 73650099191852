import { createAsyncThunk } from "@reduxjs/toolkit";
import { doGet, doPatch } from "../../utils/axios";

export const getUser = createAsyncThunk("getUser", async ({ token }) => {
  try {
    const response = await doGet(`/admins/customers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
});

export const blockUser = createAsyncThunk(
  "blockUser",
  async ({ data, token, callback }) => {
    try {
      const response = await doPatch(`/admins/block-customer`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const unblockUser = createAsyncThunk(
  "blockUser",
  async ({ data, token, callback }) => {
    try {
      const response = await doPatch(`admins/unblock-customer`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      callback && callback();
      return response;
    } catch (error) {
      throw error;
    }
  }
);
