import { createSlice } from "@reduxjs/toolkit";

import { API_EXPIRE_TIME } from "../../constants/constants";
import { deleteMenu, editMenu, getMenu, insertMenu } from "../thunks/menuThunk";
const initialState = {
  data: [],
  loading: false,
  expires: null,
  error: null,
  status: "All",
  disableConfig: false,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //!Fetch
      .addCase(getMenu.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getMenu.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getMenu.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch menus data";
        state.expires = null;
      })
      .addCase(insertMenu.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertMenu.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [action.payload, ...state.data];
        state.error = null;
      })
      .addCase(insertMenu.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to insert menu data";
      })
      .addCase(deleteMenu.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteMenu.fulfilled, (state, action) => {
        state.loading = false;

        state.data = state.data.filter((i) => i._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteMenu.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to delete menu data";
      })
      //!Edit
      .addCase(editMenu.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editMenu.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.map((i) =>
          i._id === action.payload._id ? { ...i, ...action.payload } : i
        );
        state.error = null;
      })

      .addCase(editMenu.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to edit menu data";
      });
  },
});

// export const { setStatus } = menuSlice.actions;
export default menuSlice.reducer;
