import React from "react";
import { LOGO } from "../../constants/images";

const Loader = () => {
  return (
    <div className={`loader `}>
      <div className="loader-element">
        <img src={LOGO} alt="curry heaven logo" />
      </div>
    </div>
  );
};

export default Loader;
