import { createSlice } from "@reduxjs/toolkit";

import { API_EXPIRE_TIME } from "../../constants/constants";
import {
  deleteCategory,
  editCategory,
  getCategory,
  getSubCategoryByCategory,
  insertCategory,
} from "../thunks/categoryThunk";
const initialState = {
  data: [],
  subCategoryData: [],
  loading: false,
  expires: null,
  error: null,
  status: "All",
  disableConfig: false,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //!Fetch
      .addCase(getCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch categorys data";
        state.expires = null;
      })
      .addCase(insertCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [action.payload, ...state.data];
        state.error = null;
      })
      .addCase(insertCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to insert category data";
      })
      .addCase(deleteCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;

        state.data = state.data.filter((i) => i._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to delete category data";
      })
      //!Edit
      .addCase(editCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.map((i) =>
          i._id === action.payload._id ? { ...i, ...action.payload } : i
        );
        state.error = null;
      })

      .addCase(editCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to edit category data";
      })
      .addCase(getSubCategoryByCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getSubCategoryByCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.subCategoryData = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getSubCategoryByCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch categorys data";
        state.expires = null;
      });
  },
});

// export const { setStatus } = categorySlice.actions;
export default categorySlice.reducer;
