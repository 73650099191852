import { combineReducers, configureStore } from "@reduxjs/toolkit";
import systemReducer from "./slice/systemSlice";
import authReducer from "./slice/authSlice";
import categoryReducer from "./slice/categorySlice";
import subCategoryReducer from "./slice/subCategorySlice";
import bannerReducer from "./slice/bannerSlice";
import menuReducer from "./slice/menuSlice";
import queryReducer from "./slice/querySlice";
import tableBookingReducer from "./slice/tableBookingSlice";
import blogReducer from "./slice/blogSlice";
import galleryReducer from "./slice/gallerySlice";
import faqReducer from "./slice/faqSlice";
import userReducer from "./slice/userSlice";
import ordersReducer from "./slice/ordersSlice";
import passwordReducer from "./slice/passwordSlice";
import testimonialReducer from "./slice/testimonialSlice";

import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import { PERSIST_KEY, PERSIST_VERSION } from "../utils/config";

const persistConfig = {
  key: PERSIST_KEY,
  version: PERSIST_VERSION,
  storage,
  whitelist: ["systemState", "authState"],
};

const rootReducer = combineReducers({
  systemState: systemReducer,
  authState: authReducer,
  categoryState: categoryReducer,
  subCategoryState: subCategoryReducer,
  bannerState: bannerReducer,
  menuState: menuReducer,
  queryState: queryReducer,
  tableBookingState: tableBookingReducer,
  blogState: blogReducer,
  galleryState: galleryReducer,
  faqState: faqReducer,
  userState: userReducer,
  ordersState: ordersReducer,
  testimonialState: testimonialReducer,
  passwordState: passwordReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
