import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import UIButton from "../../ui/uibutton/UIButton";
import UISelect from "../../ui/uiselect/UISelect";
import UIDialogBox from "../../ui/uidialogbox/UIDialogBox";
import { UIInput } from "../../ui/uiinput/UIInput";
import UIModal from "../../ui/uimodal/UIModal";
import UIFileInput from "../../ui/uifileinput/UIFileInput";
import { parseInputType } from "../../utils/helper";
import { errorToast, successToast } from "../../utils/toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFAQ,
  editFAQ,
  getFAQ,
  insertFAQ,
} from "../../redux/thunks/faqThunk";
import UITextEditor from "../../ui/uitexteditor/UITextEditor";
import Loader from "../../components/loader/Loader";
import Auth from "../../components/Auth";

export const ListFAQ = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(null);
  const [state, setState] = useState({
    question: "",
    answer: "",
  });
  const [error, setError] = useState({
    question: "",
    answer: "",
  });

  const handleInputField = (e) => {
    const name = e.target.name;
    const type = e.target.type;
    const required = e.target.required;
    const value = parseInputType(type, e);

    const valid = value !== null && value !== undefined && value !== "";

    setState((prev) => ({ ...prev, [name]: value ?? undefined }));

    required &&
      valid &&
      setError((prev) => ({
        ...prev,
        [name]: value ? "" : "Field is required",
      }));
  };

  const faq = useSelector((state) => state.faqState);
  const auth = useSelector((state) => state.authState);
  useEffect(() => {
    auth.token &&
      dispatch(
        getFAQ({
          token: auth.token,
        })
      );
  }, [dispatch, auth.token]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Question",
        accessor: (row) => row.question,
      },
      {
        Header: "Answer",
        accessor: (row) => (
          <div
            dangerouslySetInnerHTML={{ __html: row.answer }}
            style={{ lineHeight: "2rem" }}
          />
        ),
      },

      {
        Header: "Created At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        headerStyle: {
          width: "150px",
        },
      },
      {
        Header: "Updated At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        headerStyle: {
          width: "150px",
        },
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <UIButton
              label={<i className="fa-regular fa-pen"></i>}
              type="secondary"
              onClick={() => handleEdit(row)}
            />

            <UIButton
              label={<i className="fa-regular fa-trash"></i>}
              type="error"
              onClick={() => handleDelete(row)}
            />
          </div>
        ),
        headerStyle: {
          width: "100px",
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => {
        if (!faq?.data) return [];
        return [...faq.data].sort(
          (a, b) => new Date(b.updated) - new Date(a.updated)
        );
      }, [faq?.data]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );
  const handleDelete = (item) => setShowDeleteModal(item);
  const resetDelete = () => setShowDeleteModal(null);
  const handleEdit = (item) => {
    setShowEditModal(item);
    setState({
      question: item.question,
      answer: item.answer,
    });
  };

  const resetEdit = () => {
    setShowEditModal(null);
    setState({ question: "", answer: "" });
  };

  const handleAddSubmit = () => {
    try {
      const { question, answer } = state;
      if (!question || !answer) {
        const errors = {};
        if (!question) errors.question = "Question is required";
        if (!answer) errors.answer = "Answer is required";
        setError(errors);
        errorToast("Please verify indicated fields");
        return;
      }

      auth.token &&
        dispatch(
          insertFAQ({
            data: state,
            token: auth.token,
            callback: successInsert,
          })
        );
    } catch (error) {
      errorToast("Something went wrong with validation");
    }
  };

  const successInsert = () => {
    setState({
      question: "",
      answer: "",
    });

    setShowInsertModal(false);
    successToast("FAQ Inserted");
  };
  const handleEditSubmit = () => {
    try {
      const { question, answer } = state;
      if (!question || !answer) {
        const errors = {};
        if (!question) errors.question = "Question is required";
        if (!answer) errors.answer = "Answer is required";
        setError(errors);
        errorToast("Please verify indicated fields");
        return;
      }

      showEditModal &&
        auth.token &&
        dispatch(
          editFAQ({
            id: showEditModal?._id,
            data: state,
            token: auth.token,
            callback: successEdit,
          })
        );
    } catch (error) {
      errorToast("Something went wrong with validation");
    }
  };

  const successEdit = () => {
    setState({
      question: "",
      answer: "",
    });

    setShowEditModal(false);
    successToast("FAQ Edited");
  };

  const deleteData = () =>
    showDeleteModal &&
    auth.token &&
    dispatch(
      deleteFAQ({
        id: showDeleteModal._id,
        token: auth.token,
        callback: () => {
          setShowDeleteModal(null);
          successToast("FAQ Deleted");
        },
      })
    );
  return (
    <Auth>
      {faq.loading && <Loader />}
      <div className="framecontainer">
        <Sidebar />
        <div className="framecontainer-content">
          <div className="content-wrapper">
            <Navbar name="Frequently Asked Questions" showBars />
            <div className="listpage">
              <div className="listpage-textlink">
                <div className="listpage-textlink--text">
                  <h1>FAQ List</h1>
                  <h2>
                    <span>
                      <i className="fa-regular fa-person-circle-question"></i>
                    </span>
                    &nbsp;/ FAQ Management
                  </h2>
                </div>
                <div className="listpage-textlink--addsetting">
                  <UIButton
                    label={
                      <span>
                        <i className="fa-regular fa-plus"></i> Create
                      </span>
                    }
                    type="primary duotone"
                    onClick={() => setShowInsertModal(true)}
                  />
                </div>
              </div>

              <div className="listpage-searchentry">
                <div className="listpage-searchentry--search">
                  <UIInput
                    type="search"
                    placeholder="Search here..."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setGlobalFilter(e.target.value);
                    }}
                  />
                  <i className="fa-regular fa-magnifying-glass"></i>
                </div>
                <UISelect
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  options={[
                    { value: "5" },
                    { value: "10" },
                    { value: "25" },
                    { value: "50" },
                    { value: faq?.data?.length, displayValue: "All" },
                  ]}
                  style={{ width: "7rem" }}
                />
              </div>

              <div className="listpage-tablesection">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            style={column.headerStyle}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length} className="notfound">
                          Data not found.
                        </td>
                      </tr>
                    ) : (
                      page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell, cellIndex) => (
                              <td {...cell.getCellProps()}>
                                {cell.column.Header === "S.N"
                                  ? rowIndex + 1 + pageIndex * pageSize
                                  : cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {pageSize !== faq?.data?.length && (
                  <div className="listpage-tablesection--pagination">
                    <button
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>
                    <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </button>
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                      {">"}
                    </button>
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInsertModal && (
        <UIModal
          onClose={() => setShowInsertModal((prev) => !prev)}
          showAnimation
        >
          <div className="modalcontent-insert" style={{ width: "500px" }}>
            <h2 className="modalcontent-insert--heading">Add FAQ</h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              >
                <UIInput
                  id="question"
                  label="Question"
                  placeholder="eg. Where is restaurant located?"
                  name="question"
                  type="text"
                  isRequired
                  onChange={handleInputField}
                  value={state.question}
                  error={error.question}
                />
                <UITextEditor
                  id="answer"
                  label="Answer"
                  formatedEditorConfig={{
                    readonly: false,
                    height: 350,
                  }}
                  name="answer"
                  isRequired
                  onChange={handleInputField}
                  defaultValue={state.answer}
                  error={error.answer}
                />
              </div>
            </div>

            <div className="modalcontent-insert--actions">
              <UIButton
                label="Save"
                type="primary"
                onClick={handleAddSubmit}
                style={{ minWidth: "13rem" }}
              />
            </div>
          </div>
        </UIModal>
      )}

      {showEditModal && (
        <UIModal onClose={resetEdit} showAnimation>
          <div className="modalcontent-insert" style={{ width: "500px" }}>
            <h2 className="modalcontent-insert--heading">Edit FAQ</h2>

            <div
              className="modalcontent-insert--form"
              style={{ width: "100%" }}
            >
              <div
                className="modalcontent-insert--form__left"
                style={{ width: "100%" }}
              >
                <UIInput
                  id="question"
                  label="Question"
                  placeholder="eg. Where is restaurant located?"
                  name="question"
                  type="text"
                  isRequired
                  onChange={handleInputField}
                  value={state.question}
                  error={error.question}
                />
                <UITextEditor
                  id="answer"
                  label="Answer"
                  formatedEditorConfig={{
                    readonly: false,
                    height: 350,
                  }}
                  name="answer"
                  isRequired
                  onChange={handleInputField}
                  defaultValue={state.answer}
                  error={error.answer}
                />
              </div>
            </div>

            <div className="modalcontent-insert--actions">
              <UIButton
                label="Save"
                type="primary"
                onClick={handleEditSubmit}
                style={{ minWidth: "13rem" }}
              />
            </div>
          </div>
        </UIModal>
      )}

      {showDeleteModal ? (
        <UIDialogBox
          cancel={resetDelete}
          confirm={() => deleteData()}
          message="Are you sure you want to delete this item?"
          title="Confirm Delete"
        />
      ) : null}
    </Auth>
  );
};
