const API_BASE_URL = "https://curryheaven.reerank.com/api/v1";
const WEBSITE_BASE_URL = "https://curryheaven.reerank.com";
// const API_BASE_URL = "http://127.0.0.1:8000/api/v1";
// const WEBSITE_BASE_URL = "http://127.0.0.1:8000";
const PERSIST_VERSION = 1;
const PERSIST_KEY = "curry_heaven_admin";
const FEATURE_FLAGS = {
  newFeature: true,
  experimentalFeature: false,
};

export {
  API_BASE_URL,
  WEBSITE_BASE_URL,
  FEATURE_FLAGS,
  PERSIST_VERSION,
  PERSIST_KEY,
};
