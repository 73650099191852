import { createSlice } from "@reduxjs/toolkit";

import { API_EXPIRE_TIME } from "../../constants/constants";
import { getOrders, getOrderDetail } from "../thunks/ordersThunk";
const initialState = {
  data: [],
  orderData: [],
  loading: false,
  expires: null,
  error: null,
  status: "All",
  disableConfig: false,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetOrderData: (state) => {
      state.orderData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      //!Fetch
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getOrders.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch orderss data";
        state.expires = null;
      })
      .addCase(getOrderDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.expires = null;
      })
      .addCase(getOrderDetail.fulfilled, (state, action) => {
        state.loading = false;

        state.orderData = action.payload;
        state.expires = Date.now() + API_EXPIRE_TIME;
        state.error = null;
        state.disableConfig = false;
      })
      .addCase(getOrderDetail.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch orderss data";
        state.expires = null;
      });
  },
});

export const { resetOrderData } = ordersSlice.actions;
export default ordersSlice.reducer;
