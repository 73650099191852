import { createAsyncThunk } from "@reduxjs/toolkit";
import { doGet } from "../../utils/axios";

export const getQuery = createAsyncThunk("getQuery", async ({ token }) => {
  try {
    const response = await doGet(`/forms`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
});
